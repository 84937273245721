import React, { useState, useRef } from "react";
import Button from "../../button/Button";
import Parse from "parse";
import { getCrmUserData } from "../../../utils/ParserUtilities";

import { useStateValue } from "../../../state/context";
import UserHistoryContainer from "./UserHistoryContainer";

import "../../../styles/search/detail/job-detail-history.css";

import { apiId, jsKey, urlServer  } from '../../../constants/credentials';
import Jobs from "./Jobs";

export default function UserComments(props) {

    const [loading, setLoading] = useState(false);
    const [inputText, setInputText] = useState("");
    const [newNotes, setNewNotes] = useState([]);
    const [{agent}] = useStateValue();
    const textFied = useRef();

    const [userCRMHistory, setUserCRMHistory] = useState(null);

    const [{user}, dispatch] = useStateValue();

    const onInputChange = text => {
        setInputText(text);
    };

    const saveNote = async () => {
        setLoading(true);
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();

        const data = {
            crmUserId: agent.agentId,
            userId: props.userDetails.user.id,
            comment: inputText
        }

        let res = await Parse.Cloud.run("addUserComment", data);

        textFied.current.value = "";

        let prueba = await getCrmUserData(agent.agentId)

        const note = {
            createdAt: new Date().toString().substring(4,15),
            description: inputText,
            name: "Yo"
        }

        setNewNotes(newNotes => [...newNotes, note])
        setInputText("")
        setLoading(false);
    }

    async function getUserCRMHistory() {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();

        let pathname = window.location.href.split('/');
        let userId = pathname[pathname.length - 1];

        let res = await Parse.Cloud.run("getUserComment", { userId });
        setUserCRMHistory(res)
    }

    return(
        <>
            <div className="jobDetailColumn">
                {!user?.isGoldWoller && (
                    <>
                        <section className="noteContainer">
                            <input
                                ref={textFied}
                                className="textInput"
                                type="text"
                                placeholder="Escribe aquí"
                                onChange={e => onInputChange(e.target.value)}
                            />
                            <div className="buttonContainer">
                                <Button
                                    buttonText="Guardar"
                                    style={{ width: 85, color: "#ffe235" }}
                                    loading={loading}
                                    disabled={inputText.length > 0 ? false : true}
                                    onClick={saveNote}
                                />
                            </div>
                        </section>
                        <UserHistoryContainer
                            newNotes={newNotes}
                            getUserCRMHistory={getUserCRMHistory}
                            userCRMHistory={userCRMHistory}
                        />
                    </>
                    )}
                <Jobs history={props.history} location={props.location} />
            </div>
        </>

    )
}