import React, { useState, useEffect, useRef } from "react";
import Jobin from "jobin-client";

import { useStateValue } from "../../state/context";
import { hasClassInTree } from "../../utils/checkAncestorDOM";
import { getPaymentData,publishProManagerService } from "../../utils/ParserUtilities";
import { initialState } from "../../state/initialState";

import NavBar from "../../components/NavBar";
import DetailColumn from "../../components/search/detail/detailColumn/DetailColumn";
import HistoryColumn from "../../components/search/detail/historyColumn/HistoryColumn";
import InfoColumn from "../../components/search/detail/infoColumn/InfoColumn";
import ButtonImage from "../../components/button/ButtonImage";
import CancelModal from "../../components/modal/CancelModal";
import Alert from "../../components/modal/Alert";
import GuaranteeModal from "../../components/modal/GuaranteeModal";
import ExtrasModal from "../../components/modal/ExtrasModal";
import PaymentMethodModal from "../../components/modal/PaymentMethodModal";
import FinishLeadModal from "../../components/modal/FinishLeadModal";
import ReviewModal from "../../components/modal/ReviewModal";
import AddWeightModal from "../../components/modal/AddWeightModal";
import JobStatus from "../../components/search/detail/JobStatus";

import { apiId, jsKey, urlServer  } from '../../constants/credentials';
import Parse from 'parse';

import { Loading, MenuList } from "jobin-ui-components";

import "../../styles/search/detail/job-details.css";

export default function JobDetails(props) {
    const style = {
        loadingContainer: {
            display: "flex",
            flex: 1,
            backgroundColor: "var(--soft-grey)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        },
        buttonContainer: {
            display: "flex",
            flexDirection: "row"
        }
    };

    const [{ user, jobDetails, agent }, dispatch] = useStateValue();

    const [loading, setLoading] = useState(true);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAddOptions, setShowAddOptions] = useState(false);
    const [showNotesOptions, setShowNotesOptions] = useState(false);
    const [showExtrasModal, setShowExtrasModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showFinishLeadModal, setShowFinishLeadModal] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [showAddWeightModal, setShowAddWeightModal] = useState(false);
    const [showGuaranteeModal, setShowGuaranteeModal] = useState(false);
    const [showPublish, setShowPublish] = useState(false);
    const [showPublishDraft, setShowPublishDraft] = useState(false);
    const [showPublishProManager, setShowPublishProManager] = useState(false);
    const [addMenu, setAddMenu] = useState([]);

    const [joberFinish, setJoberFinish] = useState(null);
    const [extrasArray, setExtrasArray] = useState([]);
    const [newExtrasArray, setNewExtrasArray] = useState([]);
    const [newPrice, setNewPrice] = useState(null);
    const [newWeight, setNewWeight] = useState(null);
    const [textCancel, setTextCancel] = useState("Cancelar");
    const [isConstructionCompany, setIsConstructionCompany] = useState(null);

    const fileInput = useRef();
    const signatureInput = useRef();

    useEffect(() => {
        fetchData()

        const bodyClickHandler = e => {
            if (!hasClassInTree(e.srcElement, `filter custom add-no-hide`))
                setShowAddOptions(false);
            if (!hasClassInTree(e.srcElement, `filter custom notes-no-hide`))
                setShowNotesOptions(false);
        };

        document.body.addEventListener("click", bodyClickHandler, false);
        setTextCancel(!user.isGoldWoller && user.isConstructionCompany ? "Rechazar" : "Cancelar");
       
        setIsConstructionCompany(!user?.isGoldWoller && user?.isConstructionCompany);
        // unmount
        return () => {
            dispatch({
                type: "JOB_DETAILS_RESET",
                fixedInfo: initialState.jobDetails.fixedInfo,
                transportInfo: initialState.jobDetails.transportInfo,
                paymentsArray: initialState.jobDetails.paymentsArray,
                historyArray: initialState.jobDetails.historyArray,
                extrasArray: initialState.jobDetails.extrasArray,
                jobersArray: initialState.jobDetails.jobersArray,
                readMode: initialState.jobDetails.readMode
            });

            document.body.removeEventListener("click", bodyClickHandler, false);
        };
    }, [props.match.params.jobId]);

    const fetchData = async()=>{
        const result = await Jobin.Job.getJobById(props.match.params.jobId);
        if (result.status === "ok") {
            const job = result.result;
            const clientResult = await getPaymentData(job.job?.get("CreatedBy").id);
            dispatch({
                type: "JOB_DETAILS_JOB",
                job: job.job,
                fixedInfo: job.fixedInfo,
                transportInfo: job.transportation,
                getPayments: getPayments,
                paymentClientData:clientResult,
                readMode: job.fixedInfo?.get("RequestedAssistance") && user.isConstructionCompany
            });
            if (job.fixedInfo) {
                dispatch({
                    type: "JOB_DETAILS_JOB",
                    extrasArray: job.fixedInfo.get("Extras"),
                    paymentsArray: await getPayments(job.job),
                    jobersArray: job.fixedInfo.get("Jober")
                        ? [await job.fixedInfo.get("Jober").fetch()]
                        : []
                });
                setJoberFinish(job.fixedInfo.get("Jober"));
                setAddMenu(createAddMenu(job));
            } else if (job.transportation) {
                setAddMenu(createAddMenu(job));
                const result = await Jobin.Job.getJobersForJob(job.job);
                if (result.status === "ok") {
                    setJoberFinish(result.result[0]);
                    dispatch({
                        type: "JOB_DETAILS_JOB",
                        jobersArray: result.result,
                        paymentsArray: await getPayments(job.job)
                    });
                }
            } else {
                const result = await Jobin.Job.getJobersForJob(job.job);
                if (result.status === "ok") {
                    dispatch({
                        type: "JOB_DETAILS_JOB",
                        jobersArray: result.result
                    });

                    //search the selected Jober
                    if (
                        job.job.get("State") === "finalizado" ||
                        job.job.get("State") === "review"
                    ) {
                        for (let i = 0; i < result.result.length; i++) {
                            const jober = result.result[i];
                            if (
                                jober.get("Action") === job.job.get("State")
                            )
                                setJoberFinish(jober);
                        }
                    }
                }
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    const getPayments = async job => {
        const result = await Jobin.Job.getPayments(job);
        if (result.status === "ok") {
            dispatch({
                type: "JOB_DETAILS_JOB",
                paymentsArray: result.result
            });
        }
    };

    const publishJob = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();

        let pathname = window.location.href.split('/');
        await Parse.Cloud.run("publishJob", { jobId: pathname[pathname.length - 1] });

        window.location.reload();
    } 

    const createNotesMenu = () => {
        let userNote = null;
        let jobinNote = null;
        let joberNote = null;
        let warehouseNote = null;
        if (jobDetails.transportInfo) {
            userNote = jobDetails.transportInfo.get("UserNote");
            jobinNote = jobDetails.transportInfo.get("JobinNote");
            joberNote = jobDetails.transportInfo.get("JoberNote");
            warehouseNote = jobDetails.transportInfo.get("WarehouseNote");
        } else if (jobDetails.fixedInfo) {
            userNote = jobDetails.fixedInfo.get("UserNote");
            jobinNote = jobDetails.fixedInfo.get("JobinNote");
            joberNote = jobDetails.fixedInfo.get("JoberNote");
        }

        let url = window.location.href;

        if (user?.isConstructionCompany) {
            return [
                {
                    title: "Albarán",
                    disabled: false,
                    url: url.replace("job/","job/pdf-constructor/")
                },
            ];
        }

        return [
            {
                title: "Albarán del cliente",
                disabled: !userNote,
                url: userNote ? userNote.url() : null
            },
            {
                title: "Albarán de Wolly",
                disabled: !jobinNote,
                url: jobinNote ? jobinNote.url() : null
            },
            {
                title: "Albarán del Woller",
                disabled: !joberNote,
                url: joberNote ? joberNote.url() : null
            },
            {
                title: "Albarán de LM",
                disabled: false,
                url: url.replace("job/","job/pdflm/")
            },
            {
                title: "Albarán Genérico PC",
                disabled: false,
                url: url.replace("job/","job/generic-pdf-pc/")
            },
            {
                title: "Albarán del almacén",
                disabled: !warehouseNote,
                url: warehouseNote ? warehouseNote.url() : null
            }
        ];
    };

    const notesMenuOnClick = item => {
        setShowNotesOptions(false);
        const win = window.open(item.url, "_blank");
        win.focus();
    };

    const regenerateNotes = async () => {
        setShowNotesOptions(false);
        setLoading(true);

        if (jobDetails.fixedInfo) {
            const result = await Jobin.Job.remakeFixedNotes(
                jobDetails.fixedInfo.id
            );
            if (result.status === "ok") {
                dispatch({
                    type: "JOB_DETAILS_JOB",
                    fixedInfo: await jobDetails.fixedInfo.fetch()
                });
                setLoading(false);
            } else {
                alert(result.result);
                setLoading(false);
            }
        } else if (jobDetails.transportInfo) {
            const result = await Jobin.Job.remakeTransportNotes(
                jobDetails.transportInfo.id
            );
            if (result.status === "ok") {
                dispatch({
                    type: "JOB_DETAILS_JOB",
                    transportInfo: await jobDetails.transportInfo.fetch()
                });
                setLoading(false);
            } else {
                alert(result.result);
                setLoading(false);
            }
        }
    };

    const transformJobTo1Lead = async (jobId) => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        await Parse.Cloud.run("transformJobTo1Lead", {jobId: jobId});
        window.location.reload();
    }

    const transformJobTo3Lead = async (jobId) => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        await Parse.Cloud.run("transformJobTo3Lead", {jobId: jobId});
        window.location.reload();
    }

    const createAddMenu = job => {
        if (job.transportation) {
            return [
                {
                    title: "Añadir peso",
                    id: "addWeight",
                    disabled:
                        job.job.get("State") === "draft" ||
                        job.job.get("State") === "pending" ||
                        job.job.get("State") === "abierto" ||
                        job.job.get("State") === "full"
                            ? false
                            : true
                },
                {
                    title: "Adjuntar albaranes firmados",
                    id: "attachSignature",
                    disabled: job.job.get("State") !== "signaturePending"
                },
                {
                    title: "Adjuntar otros archivos",
                    id: "attachFiles"
                }
            ];
        } else {
            return [
                {
                    title: "Añadir extras",
                    id: "addExtra",
                    disabled:
                        job.job.get("State") === "draft" ||
                        job.job.get("State") === "pending" ||
                        job.job.get("State") === "abierto" ||
                        job.job.get("State") === "full"
                            ? false
                            : true
                },
                {
                    title: "Adjuntar albaranes firmados",
                    id: "attachSignature",
                    disabled: job.job.get("State") !== "signaturePending"
                },
                {
                    title: "Adjuntar otros archivos",
                    id: "attachFiles"
                }
            ];
        }
    };

    const addMenuOnClick = item => {
        if (item.id === "addExtra") {
            setShowAddOptions(false);
            setShowExtrasModal(true);
        } else if (item.id === "addWeight") {
            setShowAddOptions(false);
            setShowAddWeightModal(true);
        } else if (item.id === "attachFiles") {
            setShowAddOptions(false);
            // simulate input file click
            fileInput.current.click();
        } else if (item.id === "attachSignature") {
            setShowAddOptions(false);
            // simulate input file click
            signatureInput.current.click();
        }
    };

    const onFileChange = async e => {
        const files = Array.from(e.target.files);
        const result = await Jobin.Job.attachFilesToJob(
            jobDetails.job.id,
            files
        );
        if (result.status === "ok") {
            window.location.reload();
        } else {
            alert(result.result);
        }
    };

    const onSignatureChange = async e => {
        const files = Array.from(e.target.files);
        const result = await Jobin.Job.attachSignatureToJob(
            jobDetails.job.id,
            files
        );
        if (result.status === "ok") {
            window.location.reload();
        } else {
            alert(result.result);
        }
    };

    const freezeJob = async () => {
        setLoading(true);
        const result = await Jobin.Job.freeze(jobDetails.job);
        if (result.status === "ok") {
            jobDetails.job.set("State", "frozen");
        } else {
            alert(result.result);
        }
        setLoading(false);
    };

    const unfreezeJob = async () => {
        setLoading(true);
        const result = await Jobin.Job.unfreeze(jobDetails.job);
        if (result.status === "ok") {
            jobDetails.job.set("State", "abierto");
        } else {
            alert(result.result);
        }
        setLoading(false);
    };

    const renderNavBarButtons = (
        <section style={style.buttonContainer}>
            {!user.isB2b && user.permissions && user.permissions.job.edit && jobDetails.job && jobDetails.job.get("State") === "standby" && (
                <div>
                    <div className={`filter custom freeze-no-hide`}>
                        <ButtonImage
                            src={require("../../assets/switch-inactive.svg")}
                            buttonText="Congelar"
                            onClick={freezeJob}
                        />
                    </div>
                    <div className={`filter custom publish-no-hide`}>
                        <ButtonImage
                            src={require("../../assets/available.svg")}
                            buttonText="Publicar"
                            onClick={() => setShowPublish(true)}
                        />
                    </div>
                </div>
            )}
            {user.permissions && user.permissions.job.edit && jobDetails.job && jobDetails.job.get("State") === "frozen" && (
                <div>
                    <div className={`filter custom unfreeze-no-hide`}>
                        <ButtonImage
                            src={require("../../assets/switch-active.svg")}
                            buttonText="Descongelar"
                            onClick={unfreezeJob}
                        />
                    </div>
                    <div className={`filter custom publish-no-hide`}>
                        <ButtonImage
                            src={require("../../assets/available.svg")}
                            buttonText="Publicar"
                            onClick={() => setShowPublish(true)}
                        />
                    </div>
                </div>
            )}
            {user.permissions && user.permissions.job.edit && (jobDetails.fixedInfo || jobDetails.transportInfo) && (
                <div>
                    {jobDetails.job.get("State") !== "falso" &&
                        jobDetails.job.get("State") !== "eliminado" && (
                            <div className={`filter custom add-no-hide`}>
                                <input
                                    type="file"
                                    multiple={true}
                                    ref={fileInput}
                                    onChange={onFileChange}
                                    style={{ display: "none" }}
                                />
                                <input
                                    type="file"
                                    multiple={true}
                                    ref={signatureInput}
                                    onChange={onSignatureChange}
                                    style={{ display: "none" }}
                                    accept={".pdf,image/*"}
                                />
                                <ButtonImage
                                    src={require("../../assets/detail/add-black.svg")}
                                    buttonText="Añadir"
                                    onClick={() => setShowAddOptions(true)}
                                    disabled={jobDetails.readMode}
                                />
                                {showAddOptions && (
                                    <MenuList
                                        items={addMenu}
                                        buttonItemSelected={addMenuOnClick}
                                        menuStyle={{ top: 50 }}
                                    />
                                )}
                            </div>
                        )}
                    {user.permissions && user.permissions.job.edit &&(jobDetails.job.get("State") === "finalizado" ||
                        jobDetails.job.get("State") === "review") && (
                        <div className={`filter custom waranty-no-hide`}>
                            <ButtonImage
                                src={require("../../assets/refresh.svg")}
                                buttonText={
                                    jobDetails.fixedInfo ? "Garantía" : "Cambio"
                                }
                                onClick={!jobDetails.readMode ? () => setShowGuaranteeModal(true) : null}
                            />
                        </div>
                    )}
                    <div className={`filter custom notes-no-hide`}>
                        <ButtonImage
                            src={require("../../assets/detail/notes-black.svg")}
                            buttonText="Albaranes"
                            onClick={() => setShowNotesOptions(true)}
                            disabled={jobDetails.readMode}
                        />
                        {showNotesOptions && (
                            <MenuList
                                menuStyle={{ top: 50 }}
                                items={createNotesMenu()}
                                buttonItemSelected={notesMenuOnClick}
                                aditionalAction={{
                                    buttonText: "Regenerar albaranes",
                                    aditionalOnClick: regenerateNotes
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
            {!user?.isGoldWoller && user?.permissions?.job.edit && (<div style={{ display: "flex" }}>
                {!jobDetails.fixedInfo && (
                    <>
                        <label for="file-upload" class="custom-file-upload">
                            <img src={require("../../assets/detail/add-black.svg")} style={{height:20, width: 20}}></img> Añadir
                        </label>
                        <input
                            type="file"
                            id="file-upload"
                            multiple={true}
                            ref={fileInput}
                            onChange={onFileChange}
                        />
                    </>
                )}
                
                <ButtonImage
                    src={require("../../assets/detail/finish-black.svg")}
                    buttonText="Finalizar"
                    onClick={() => {
                        if (jobDetails.transportInfo || jobDetails.fixedInfo) {
                            setShowAlert(true);
                        } else {
                            setShowFinishLeadModal(true);
                        }
                    }}
                    disabled={
                        jobDetails.readMode || (jobDetails.job && jobDetails.job.get("State") === "full"
                            ? false
                            : true)
                    }
                />
                <ButtonImage
                    src={require("../../assets/detail/cancel-black.svg")}
                    buttonText={textCancel}
                    onClick={() => setShowCancelModal(true)}
                    disabled={
                        jobDetails.readMode || (jobDetails.job &&
                        (jobDetails.job.get("State") === "finalizado" ||
                            jobDetails.job.get("State") === "eliminado" ||
                            jobDetails.job.get("State") === "falso" ||
                            jobDetails.job.get("State") === "review" ||
                            jobDetails.job.get("State") === "signaturePending")
                            ? true
                            : false)
                    }
                />
                {!isConstructionCompany && jobDetails?.job?.get("TotalSlots") == 3 && (
                    <ButtonImage
                        src={require("../../assets/transform.svg")}
                        buttonText="Transformar: 1 woller max"
                        onClick={() => transformJobTo1Lead(jobDetails?.job?.id)}
                        disabled={false}
                    />
                )}

                {!isConstructionCompany && jobDetails?.job?.get("TotalSlots") == 1 && (
                    <ButtonImage
                        src={require("../../assets/transform.svg")}
                        buttonText="Transformar: 3 woller max"
                        onClick={() => transformJobTo3Lead(jobDetails?.job?.id)}
                        disabled={false}
                    />
                )}
                
            </div>)}
            { user?.permissions?.job.edit && jobDetails.job &&
                (jobDetails.job.get("State") === "finalizado" ||
                    jobDetails.job.get("State") === "signaturePending" ||
                    jobDetails.job.get("State") === "review") && (
                    <div>
                        <ButtonImage
                            src={require("../../assets/star-fill.svg")}
                            buttonText="Valorar"
                            onClick={() => setShowReviewModal(true)}
                            disabled={
                                jobDetails.job &&
                                jobDetails.job.get("State") === "review"
                                    ? true
                                    : false
                            }
                        />
                    </div>
                )}
            {user?.permissions?.job.edit && jobDetails.job &&
                jobDetails.job.get("State") === "draft" && !jobDetails.job.get("Business")?.has("User") &&
                jobDetails.paymentsArray.length === 0 && (
                        <ButtonImage
                            src={require("../../assets/available.svg")}
                            buttonText="Publicar"
                            onClick={() => {
                                setShowPublishDraft(true);
                            }}
                            disabled={jobDetails.readMode}
                        />

                )}
            {user?.permissions?.job.edit && jobDetails.job &&
                jobDetails.job.get("State") === "draft" && jobDetails.job?.get("Business")?.get("User") && jobDetails.paymentsArray.length > 0 && (
                        <ButtonImage
                            src={require("../../assets/available.svg")}
                            buttonText="Publicar ProManager"
                            onClick={() => {
                                setShowPublishProManager(true);
                            }}
                        />
                )}
            {user?.permissions?.job.edit && jobDetails.job &&
                jobDetails.job.get("State") === "falso" && (
                    <div className={`filter custom publish-no-hide`}>
                        <ButtonImage
                            src={require("../../assets/available.svg")}
                            buttonText="Publicar"
                            onClick={() => publishJob()}
                        />
                    </div>
            )}
        </section>
    );

    const saveExtras = async (rExtrasArray, rNewExtrasArray) => {
        //User added more extras. Open payment method modal
        if (rNewExtrasArray.length > 0) {
            //Get the extra price
            const calc = rNewExtrasArray.reduce((total, extra) => {
                return total + extra.pricePerUnit * extra.units;
            }, 0);

            setNewPrice((calc * 1.21).toFixed(2));
            setShowExtrasModal(false);
            setNewExtrasArray(rNewExtrasArray);

            //If the job is a draft, add the extras with no charge
            if (jobDetails.job.get("State") === "draft") {
                setLoading(true);
                const result = await Jobin.Job.addNewExtrasToDraftJob(
                    jobDetails.job,
                    rExtrasArray
                );
                if (result.status === "ok") {
                    dispatch({
                        type: "JOB_DETAILS_JOB",
                        extrasArray: rExtrasArray,
                        fixedInfo: await jobDetails.fixedInfo.fetch(),
                        paymentsArray: await getPayments(jobDetails.job)
                    });
                } else {
                    alert(result.result);
                }
                setLoading(false);
            } else {
                setExtrasArray(rExtrasArray);
                setShowPaymentModal(true);
            }
        }
    };

    const savePaymentMethod = async (paymentMethod, input, summary) => {
        setShowPaymentModal(false);

        //Is a charge
        if (newPrice >= 0) {
            //Is a draft job and want to publish it
            if (jobDetails.job.get("State") === "draft") {
                setLoading(true);
                const result = await Jobin.Job.publishDraftJob(
                    jobDetails.job,
                    paymentMethod,
                    input
                );
                if (result.status === "ok") {
                    dispatch({
                        type: "JOB_DETAILS_JOB",
                        job: jobDetails.job.set("State", "pending"),
                        fixedInfo: await jobDetails.fixedInfo.fetch(),
                        paymentsArray: await getPayments(jobDetails.job),
                        historyArray: await jobDetails.getHistoryOfJob(
                            jobDetails.job
                        )
                    });
                } else if(agent.dept == "ConstructionCompany") {
                    dispatch({
                        type: "JOB_DETAILS_JOB",
                        job: jobDetails.job.set("State", "pending"),
                        fixedInfo: await jobDetails.fixedInfo.fetch(),
                        paymentsArray: await getPayments(jobDetails.job),
                        historyArray: await jobDetails.getHistoryOfJob(
                            jobDetails.job
                        )
                    });
                
                } else {
                    alert(result.result);
                }
                setLoading(false);
            } else {
                //Is a fixed Job and need to add extras
                if (newExtrasArray.length > 0) {
                    setLoading(true);
                    const result = await Jobin.Job.addNewExtras(
                        jobDetails.job,
                        extrasArray,
                        paymentMethod,
                        input
                    );
                    if (result.status === "ok") {
                        dispatch({
                            type: "JOB_DETAILS_JOB",
                            extrasArray: extrasArray,
                            paymentsArray: await getPayments(jobDetails.job)
                        });
                    }
                    setNewExtrasArray([]);
                    setLoading(false);
                }
                //Is a transportation and need to add weight
                else if (newWeight > 0) {
                    addWeight(newWeight, paymentMethod);
                }
            }
        }
        //Is a refound
        else {
            if (newExtrasArray.length > 0) {
                setLoading(true);
                const result = await Jobin.Job.addNewExtras(
                    jobDetails.job,
                    extrasArray,
                    null,
                    null,
                    summary,
                    input
                );
                if (result.status === "ok") {
                    dispatch({
                        type: "JOB_DETAILS_JOB",
                        extrasArray: extrasArray,
                        paymentsArray: await getPayments(jobDetails.job)
                    });
                }
                setNewExtrasArray([]);
                setLoading(false);
            } else if (newWeight !== 0) {
                addWeight(newWeight, undefined, summary, input);
            }
        }
    };

    const finishJob = async jober => {
        setLoading(true);
        const result = await Jobin.Job.finishJob(
            jobDetails.job,
            jober,
            jobDetails.fixedInfo
        );
        if (result.status === "ok") {
            await jobDetails.getHistoryOfJob(jobDetails.job);
            dispatch({
                type: "JOB_DETAILS_JOB",
                job: await jobDetails.job.fetch()
            });
            setJoberFinish(jober);
            setLoading(false);
        } else {
            setLoading(false);
            alert(result.result);
        }
        setLoading(false);
    };

    const reviewSaved = async () => {
        await jobDetails.getHistoryOfJob(jobDetails.job);
        dispatch({
            type: "JOB_DETAILS_JOB",
            job: await jobDetails.job.fetch()
        });
        setShowReviewModal(false);
    };

    const jobCanceled = async action => {
        await jobDetails.getHistoryOfJob(jobDetails.job);
        dispatch({
            type: "JOB_DETAILS_JOB",
            job: jobDetails.job.set("State", action)
        });
        setShowCancelModal(false);
    };

    const addWeight = async (weight, paymentMethod, summary, iban) => {
        setLoading(true);
        const result = await Jobin.Job.addWeight(
            jobDetails.transportInfo,
            weight,
            paymentMethod,
            summary,
            iban
        );
        if (result.status === "ok") {
            dispatch({
                type: "JOB_DETAILS_JOB",
                transportInfo: await jobDetails.transportInfo.fetch(),
                paymentsArray: await getPayments(jobDetails.job)
            });
        } else {
            alert(result.result);
        }
        setLoading(false);
    };

    const publishFixed = () => {
        setShowPublish(false);
        const address = jobDetails.job.get("Address");
        const postalCode = jobDetails.job.get("PostalCode");
        const country = jobDetails.job.get("Country");
        const idx = address.lastIndexOf(" ");

        let faddress = `${address}, ${postalCode}, ${country}`;
        if (idx > -1)
            faddress = `${address.substr(
                0,
                idx
            )} ${postalCode}, ${address.substr(idx)}, ${country}`;

        dispatch({
            type: "JOB_LEAD_TO_FIXED",
            fromLeadToFixed: true,
            name: jobDetails.job.get("CreatedBy").get("Name"),
            phone: jobDetails.job.get("CreatedBy").get("Phone"),
            email: jobDetails.job.get("CreatedBy").get("email"),
            leadToFixedId: jobDetails.job.id
        });
        dispatch({
            type: "JOB_CATEGORY_GUILD_ID_TYPE",
            categoryId: jobDetails.job.get("Vertical").id,
            categoryType: jobDetails.job.get("Vertical").get("Type")
        });
        dispatch({
            type: "JOB_CATEGORY_GUILD_SUBCATEGORY",
            subCategoryId: jobDetails.job.get("Vertical").id == "Z7HBY4VaNi" ?  jobDetails.job.get("Vertical").id : jobDetails.job.get("Subvertical") ? jobDetails.job.get("Subvertical").id : jobDetails.job.get("Vertical").id
        });
        dispatch({
            type: "JOB_LOCATE",
            address: faddress,
            address2: "",
            addressSelected: false,
            center: {
                lat: jobDetails.job.get("Location")._latitude,
                lng: jobDetails.job.get("Location")._longitude
            },
            disabledBtn: true,
            postalCode: postalCode
        });
        dispatch({
            type: "JOB_DESCRIPTION",
            message: jobDetails.job.get("Description"),
            disabledBtn: true
        });
        props.history.push({
            pathname: "/job/new/location?from=details",
            state: {
                inner: true
            }
        });
    };

    const publishProManager = async () => {
        setLoading(true);
        const result=publishProManagerService({jobId:jobDetails.job.id})

        if (result && result !="error") {
            window.location.reload();
            
        } 
        setLoading(false);
    };

    const draftToLead = async () => {
        setLoading(true);
        const result = await Jobin.Job.draftToLead(jobDetails.job);
        if (result.status === "ok") {
            dispatch({
                type: "JOB_DETAILS_JOB",
                job: await jobDetails.job.fetch(),
                transportInfo: null,
                paymentsArray: null
            });
            await jobDetails.getHistoryOfJob(jobDetails.job);
        } else {
            alert(result.result);
        }
        setLoading(false);
    };

    const applyGuarantee = async description => {
        setLoading(true);
        const result = await Jobin.Job.applyGuarantee(
            jobDetails.job,
            description
        );
        if (result.status === "ok") {
            props.history.push({
                pathname: "/job/" + result.result,
                search: props.location.search
            });
        } else {
            alert(result.result);
        }
        setLoading(false);
    };

    const changeProduct = async () => {
        setLoading(true);
        const result = await Jobin.Job.changeTransportProduct(
            jobDetails.job,
            "change"
        );
        if (result.status === "ok") {
            props.history.push({
                pathname: "/job/" + result.result,
                search: props.location.search
            });
        } else {
            alert(result.result);
        }
        setLoading(false);
    };

    const returnProduct = async () => {
        setLoading(true);
        const result = await Jobin.Job.changeTransportProduct(
            jobDetails.job,
            "return"
        );
        if (result.status === "ok") {
            props.history.push({
                pathname: "/job/" + result.result,
                search: props.location.search
            });
        } else {
            alert(result.result);
        }
        setLoading(false);
    };

    if (loading) {
        return (
            <section style={style.loadingContainer}>
                <Loading />
            </section>
        );
    } else {
        return (
            <section>
                {showCancelModal && (
                    <CancelModal
                        isVisible={showCancelModal}
                        job={jobDetails.job}
                        onClose={() => setShowCancelModal(false)}
                        jobCanceled={jobCanceled}
                    />
                )}
                {showAlert && (
                    <Alert
                        isVisible={showAlert}
                        onClose={() => setShowAlert(false)}
                        title="Finalizar servicio"
                        subtitle="Marcando esta opción se indica que el servicio se ha realizado con éxito. Para que el servicio se autofacture al Woller, debe adjuntarse el albarán firmado."
                        buttonText="Finalizar"
                        onClick={() => {
                            setShowAlert(false);
                            finishJob(jobDetails.jobersArray[0]);
                        }}
                    />
                )}
                {showPublish && (
                    <Alert
                        isVisible={showPublish}
                        onClose={() => setShowPublish(false)}
                        title="Publicar servicio"
                        subtitle="¿Qué tipo de servicio quieres que sea? Si seleccionas precio fijo te pediremos más datos para completar el proceso. Si decides publicarlo como lead, se enviará a todos los Wollers disponibles para que envíen presupuesto."
                        buttonText={"Publicar precio cerrado"}
                        secondButtonText={"Publicar lead"}
                        onClick={() => {
                            setShowPublish(false);
                            publishFixed();
                        }}
                        secondOnClick={() => {
                            setShowPublish(false);
                            unfreezeJob();
                        }}
                    />
                )}
                {showPublishDraft && (
                    <Alert
                        isVisible={showPublishDraft}
                        onClose={() => setShowPublishDraft(false)}
                        title={agent.dept == "ConstructionCompany" ? "Aceptar incidencia":"Publicar servicio"}
                        subtitle={agent?.dept == "ConstructionCompany" ? "Pulse aceptar para comenzar con la gestión de la incidencia procedente.":"¿Qué tipo de servicio quieres que sea? Si seleccionas precio fijo te pediremos más datos para completar el proceso. Si decides publicarlo como lead, se enviará a todos los Wollers disponibles para que envíen presupuesto."}
                        buttonText={agent?.dept == "ConstructionCompany" ? "Aceptar":"Publicar precio cerrado"}
                        secondButtonText={agent?.dept == "ConstructionCompany" ? false:"Publicar lead"}
                        onClick={() => {
                            setShowPublishDraft(false);
                            setNewPrice(
                                (
                                    Number(jobDetails.job.get("PriceMax")) *
                                    1.21
                                ).toFixed(2)
                            );
                            if (isConstructionCompany)
                                savePaymentMethod("business").then();
                            else
                                setShowPaymentModal(true);
                        }}
                        secondOnClick={() => {
                            setShowPublishDraft(false);
                            draftToLead();
                        }}
                    />
                )}
                {showPublishProManager && (
                    <Alert
                        isVisible={showPublishProManager}
                        onClose={() => setShowPublishProManager(false)}
                        title="Publicar servicio"
                        subtitle="¿Estás seguro de que deseas publicar este servicio?"
                        buttonText={"Publicar servicio"}
                        onClick={() => {
                            setShowPublishDraft(false);
                            publishProManager()
                        }}
                        secondOnClick={() => {
                            setShowPublishDraft(false);
                            draftToLead();
                        }}
                    />
                )}
                {showExtrasModal && (
                    <ExtrasModal
                        subvertical={jobDetails.job.get("Subvertical")}
                        isVisible={showExtrasModal}
                        onClose={() => setShowExtrasModal(false)}
                        extrasArray={jobDetails.extrasArray}
                        saveExtras={saveExtras}
                        isUrgent={jobDetails.job.get("Priority") === "urgent"}
                        basicPrice={jobDetails.fixedInfo.get("BasicPrice")}
                    />
                )}
                {showPaymentModal && (
                    <PaymentMethodModal
                        isVisible={showPaymentModal}
                        initialView={newPrice >= 0 ? "main" : "refound"}
                        price={newPrice}
                        savePaymentMethod={savePaymentMethod}
                        onClose={() => {
                            setNewPrice(null);
                            setNewWeight(null);
                            setNewExtrasArray([]);
                            setShowPaymentModal(false);
                        }}
                        job={jobDetails.job}
                    />
                )}
                {showFinishLeadModal && (
                    <FinishLeadModal
                        isVisible={showFinishLeadModal}
                        onClose={() => setShowFinishLeadModal(false)}
                        jobersArray={jobDetails.jobersArray}
                        joberSelected={jober => {
                            setShowFinishLeadModal(false);
                            finishJob(jober);
                        }}
                    />
                )}
                {showReviewModal && (
                    <ReviewModal
                        isVisible={showReviewModal}
                        onClose={() => setShowReviewModal(false)}
                        job={jobDetails.job}
                        jober={joberFinish}
                        reviewSaved={reviewSaved}
                        onReviewSaved={setShowReviewModal}
                    />
                )}
                {showAddWeightModal && (
                    <AddWeightModal
                        isVisible={showAddWeightModal}
                        onClose={() => setShowAddWeightModal(false)}
                        job={jobDetails.job}
                        currentWeight={jobDetails.transportInfo.get("Weight")}
                        addWeight={(weight, price) => {
                            setNewWeight(weight);
                            if (price !== 0) {
                                setNewPrice(price.toFixed(2));
                                setShowAddWeightModal(false);
                                setShowPaymentModal(true);
                            } else {
                                setShowAddWeightModal(false);
                                addWeight(weight);
                            }
                        }}
                    />
                )}
                {showGuaranteeModal &&
                    (jobDetails.fixedInfo ? (
                        <GuaranteeModal
                            isVisible={showGuaranteeModal}
                            onClose={() => setShowGuaranteeModal(false)}
                            onClick={description => {
                                setShowGuaranteeModal(false);
                                applyGuarantee(description);
                            }}
                        />
                    ) : (
                        <Alert
                            isVisible={showGuaranteeModal}
                            onClose={() => setShowGuaranteeModal(false)}
                            title={"¿Es un cambio o una devolución?"}
                            subtitle={
                                "Se entiende como cambio si el cliente desea devolver un producto y recibir otro en el mismo domicilio. Si el cliente solo quiere devolverlo y no recibir ningún producto nuevo selecciona 'Devolución'"
                            }
                            buttonText={"Cambio de producto"}
                            secondButtonText={"Devolución"}
                            onClick={() => {
                                setShowGuaranteeModal(false);
                                changeProduct();
                            }}
                            secondOnClick={() => {
                                setShowGuaranteeModal(false);
                                returnProduct();
                            }}
                        />
                    ))}
                <NavBar
                    history={props.history}
                    location={props.location}
                    title={"Detalle"}
                    buttonView={renderNavBarButtons}
                />
                <div className="detailContainer">
                    <DetailColumn
                        history={props.history}
                        location={props.location}
                    />
                    <HistoryColumn />
                    <InfoColumn
                        history={props.history}
                        location={props.location}
                        fetchData={fetchData}
                    />
                </div>
                <JobStatus />
            </section>
        );
    }
}
