import React, { useState, useEffect } from "react";
import { useStateValue } from "../../state/context";
import Jobin from "jobin-client";

import {
    findGuildByBusiness as findGuildByBusiness_,
    getClientByPhone,
    updateACRGuild,
    updateACRDefects
} from "../../utils/ParserUtilities";

import MainModal from "./MainModal";
import Button from "../button/Button";
import { Input } from "jobin-ui-components";
import '../../styles/modal/new-service-modal.css';

const NewServiceModal = props => {

    const [promoSelected, setPromoSelected] = useState();
    const [{ agent }, dispatch] = useStateValue();
    const [optionsGuilds, setOptionsGuilds] = useState(null);
    const [typeGuild, setTypeGuild] = useState([]);
    const [typeDefect, setTypeDefect] = useState([]);
    const [serviceDetail, setServiceDetail] = useState("");
    const [serviceFiles, setServiceFiles] = useState([]);
    const [imgError, setImgError] = useState(false);
    const [serviceBuilding, setServiceBuilding] = useState([]);
    const [serviceAsset, setServiceAsset] = useState([]);
    const [userName, setUserName] = useState(null);
    const [userId, setUserId] = useState(null);
    const [userPhone, setUserPhone] = useState(null);
    const [userPhoneError, setUserPhoneError] = useState(false);
    const [userEmail, setUserEmail] = useState(null);
    const [userEmailError, setUserEmailError] = useState(false);
    const [newClient, setNewClient] = useState(false);

    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    /*ToDo 
        #add serviceType (lead/fixed)
        #add extras (fixed)
        #add Service date (lead/fixed)
    */


    useEffect(() => {
        getGuildsAndDefects();
    }, []);


    useEffect(() => {
        if (
            serviceDetail &&
            serviceFiles &&
            serviceBuilding &&
            !imgError&&
            serviceAsset &&
            userPhone &&
            (!newClient ||(newClient && userName &&
            userEmail))
        ) {
            setSaveBtnDisabled(false);
        }
    }, [serviceDetail ,serviceFiles ,serviceBuilding ,serviceAsset ,userPhone ,userName ,userEmail]);

    const handleCreateService = async () => {
        setLoading(true);
        let newUser
        if(newClient){
            newUser = await Jobin.Client.createClient(userName,userPhone,userEmail);
        }
        try {
            const fixed = await Jobin.Job.createFixedJob(
                userId && !newClient ? userId:newUser.result.id,
                typeGuild.vertical.id,
                typeGuild.vertical.name,
                undefined,
                serviceBuilding.location.latitude,
                serviceBuilding.location.longitude,
                null,
                serviceDetail,
                serviceFiles,
                'fixed',
                'business',
                'bi-web',
                true,
                3,
                serviceBuilding.formattedData,
                null,
                "draft",
                promoSelected.business,
                null,
                null,
                1,
                serviceAsset.id,
                true
            );
            if (fixed.status === "ok") {
           
                await updateACRGuild({
                    jobId: fixed.result.job,
                    guildId: typeGuild.id,
                }); 
                await updateACRDefects( {
                    jobId: fixed.result.job,
                    defectId: typeDefect.id,
                });//edit defect only

                props.refresh()
                props.onClose()
            }

        } catch (error) {
            console.error(error)
            setLoading(false)
        }
        
    };
    const getGuildsAndDefects = async () => {
        let businessId = agent?.business?.id
        if (businessId) {
            let res = await findGuildByBusiness_({ businessId: businessId })
            setOptionsGuilds(res);
        }
    }
    const handleUserEmail = input => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,4}$/.test(input)) {
            setUserEmail(input);
            setUserEmailError(false);
        } else {
            setUserEmail(null);
            setUserEmailError(true);
            setSaveBtnDisabled(true);
        }
    };
    const handleClientPhone = async (phone) => {
        if(/(((?:^\+(?:34|33|351)[0-9]{9})$)|^(\d{9})+$)/.test(phone)){
            setUserPhone(phone)
            setUserPhoneError(false)
            let result = await getClientByPhone(phone);
            if (result){
                setUserId(result.id)
                setNewClient(false)
            } else {
                setNewClient(true)
            }
                
        }else if(phone.length<1){
            setUserPhoneError(false)
            setNewClient(false)
        }else{
            setUserPhoneError(true)
            setNewClient(false)
        }
    }
    const handlePromoSelected = (event) => {
        if(event.target.value =="false"){
            setPromoSelected(false)
        }else{
            let selectedOption = props.promotion.filter(promo => promo.id == event.target.value).pop()
            setServiceBuilding([])
            setServiceAsset([])
            let locations = selectedOption?.buildings.map((building) => {
                return {
                    ...building,
                    "formattedData": `${building.publicWayType}  ${building.publicWayName} ${building.publicWayNumber}, ${building.postalCode}, ${building.municipality}, ${building.province}`,
                    "assets": building.assets.map((asset) => {
                        return {
                            ...asset,
                            "formattedData": `${asset.stairs ? `Escalera: ${asset.stairs};` : ""} ${asset.floorNumber ? `Piso: ${asset.floorNumber};` : ""} ${asset.door ? `Puerta: ${asset.door};` : ""} ${asset.block ? `Bloque: ${asset.block}` : ""}`.trim().replace(/;$/, "")
                        }
                    })
                }
                
            })
            selectedOption.buildings = locations
            setPromoSelected(selectedOption)
        }
    }
    const handleChangeSelectGuild = async (event) => {
        if(!event.target.value){
            return null
        }
        let guild = optionsGuilds.filter(guild_ => guild_.id == event.target.value).pop()
        setTypeGuild(guild)
    }
    const handleChangeSelectDefect = async (event) => {
        if(!event.target.value){
            return null
        }
        let defect = typeGuild.defects.filter(defect_ => defect_.id == event.target.value).pop()
        setTypeDefect(defect)
    }
    const handleChangeSelectBuilding = async (event) => {
        if(!event.target.value){
            return null
        }
        let building = promoSelected?.buildings?.filter(building_ => building_.id == event.target.value).pop()
        setServiceBuilding(building)
    }
    const handleChangeSelectAsset = async (event) => {
        if(!event.target.value){
            return null
        }
        let asset = serviceBuilding.assets.filter(asset_ => asset_.id == event.target.value).pop()
        setServiceAsset(asset)
    }
    const handlePhotos = async (files, index) => {
        files = [...files]
        if (files.length>5){
            
            setImgError(true)
        }else{
            setImgError(false)
        }

        let images = files.map((file) => {
            if (file.size > 10000000) {
                console.log("El archivo seleccionado es muy grande!");
            } else {
                return file
            }
        })
      
        setServiceFiles(
                images.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
        )
    
    }
    const removePhotos = async (index) => {
        let clearedArray = serviceFiles
        clearedArray[index] = null
        clearedArray = clearedArray.filter(x => x)
        if (clearedArray.length<=5){
            setImgError(false)
        }
        setServiceFiles(clearedArray.filter(x => x))
    }

  

    return (
        <MainModal
            title={"Crear nuevo servicio"}
            subtitle={"Introduce la información del servicio proporcionado por el cliente."}
            isVisible={props.isVisible}
            onClose={props.onClose}
            big={true}
            classNamesModal={{ modal: "new-service-modal" }}
        >
            <div className="list-container">
                <label className="subTitle">{"Selecciona la promocion"}</label>
                <select
                    value={promoSelected?.id}
                    onChange={handlePromoSelected}
                    defaultValue={null}
                >
                    <option value={false}  >Selecciona una opción</option>
                    {props.promotion?.map((promo) => {
                        return (<option key={promo.business} value={promo.id}>{promo.name}</option>)
                    })}

                </select>
            </div>

            <div className={promoSelected ? "form-container-active" : "form-container"}>
                <div className="container-input">
                    <div className="input-slot">
                        <label className="subTitle">{"Selecciona el gremio"}</label>
                        <select
                            value={typeGuild.id}
                            onChange={handleChangeSelectGuild}
                            defaultValue={null}
                        >
                            <option value={false}  >Selecciona una opción</option>
                            {
                                optionsGuilds?.map(element => {
                                    return (<option key={element.id} value={element.id}>{element.name}</option>)
                                })
                            }

                        </select>
                    </div>
                    <div className="input-slot ">
                        <label className="subTitle">{"Selecciona el defecto"}</label>

                        <select
                            value={typeDefect.id}
                            onChange={handleChangeSelectDefect}
                            defaultValue={null}
                        >
                            <option value={false}  >Selecciona una opción</option>

                            {
                                typeGuild?.defects?.map(element => {
                                    return (<option key={element.id} value={element.id} >{element.name}</option>)
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="container-input">
                    <div className="input-slot unique">
                        <label className="subTitle">{"Descripcion del servicio"}</label>
                        <textarea
                            onChange={e =>setServiceDetail(e.target.value)}
                            value={serviceDetail}
                        />
                    </div>
                </div>
                <div className="container-input">
                    <div className="input-slot unique">
                        <label htmlFor="photo" className="add-photos"> Añadir documentación <span className={imgError ? "error":""}> (max 5 archivos){imgError && "selecciona una para eliminarla"}</span></label>
                        <input type="file" id="photo" accept="image/jpeg, image/png" multiple hidden onChange={(event) => handlePhotos(event.target.files)} />
                        <div className="photo-container">
                            {serviceFiles && serviceFiles.map((image, index) => (
                                <img
                                    className="photo"
                                    key={"photo" +index+ image.name}
                                    src={image.preview}
                                    alt="photo" onClick={() => removePhotos(index)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="container-input">
                    <div className="input-slot ">
                        <label className="subTitle">{"Selecciona la ubicacion"}</label>
                        <select
                            value={serviceBuilding?.id}
                            onChange={handleChangeSelectBuilding}
                            defaultValue={null}
                        >
                            <option value={false}  >Selecciona una opción</option>
                            {
                                promoSelected?.buildings?.map(element => {
                                    return (<option key={element.id} value={element.id}>{element.formattedData}</option>)
                                })
                            }

                        </select>
                    </div>
                    <div className="input-slot ">
                        <label className="subTitle">{"Selecciona el detalle de la ubicacion"}</label>
                        <select
                            value={serviceAsset?.id}
                            onChange={handleChangeSelectAsset}
                            defaultValue={null}
                        >
                            <option value={false}  >Selecciona una opción</option>

                            {
                                serviceBuilding?.assets?.map(element => {
                                    return (<option key={element.id} value={element.id} >{element.formattedData}</option>)
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="container-input">
                    <div className={newClient ? "input-slot triple active":"input-slot triple "}>
                        <label className="subTitle">{"Telefono del cliente"}</label>

                        <Input
                            badInput={userPhoneError}
                            onChange={e => handleClientPhone(e.target.value)}
                            defaultValue={userPhone}
                        />
                        <span className={userPhoneError ? "error" : " error hidden"}>
                            Numero de telefono valido 
                        </span>
                    </div>
                    <div className={newClient ? "input-slot triple active":"input-slot triple hidden"}>
                        <label className="subTitle">{"Nombre del cliente"}</label>
                        <Input
                            onChange={e => setUserName(e.target.value)}
                            defaultValue={userName}
                        />
                    </div>
                    <div className={newClient ? "input-slot triple active":"input-slot triple hidden"}>
                        <label className="subTitle">{"Correo electronico"}</label>

                        <Input
                            style={{ display: "flex" }}
                            badInput={userEmailError}
                            onChange={e => handleUserEmail(e.target.value)}
                            defaultValue={userEmail}
                        />
                        <span className={userEmailError ? "error" : " error hidden"}>
                            Correo electronico valido
                        </span>
                    </div>

                </div>
                
                <Button
                    style={{ marginTop: 16 }}
                    disabled={saveBtnDisabled}
                    buttonText="Crear servicio"
                    onClick={handleCreateService}
                    loading={loading}
                />

            </div>
        </MainModal>
    );
};

export default NewServiceModal;