import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { Loading } from "jobin-ui-components";
import { useStateValue } from "../../state/context";
import { initialState } from "../../state/initialState";

import Details from "../../components/business/detail/Details";
import Jobs from "../../components/business/detail/Jobs";
import Billing from "../../components/business/detail/Billing";
import NavBar from "../../components/NavBar";
import Footer from "../../components/business/detail/Footer";

import "../../styles/search/detail/job-details.css";

export default function BusinessDetails(props) {
    const style = {
        loadingContainer: {
            display: "flex",
            flex: 1,
            backgroundColor: "var(--soft-grey)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        },
        buttonContainer: {
            display: "flex",
            flexDirection: "row"
        }
    };

    const [{agent,currentSession}, dispatch] = useStateValue();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getBusiness() {
            const business = await Jobin.Business.getById(
                props.match.params.businessId
            );
            if (business.status === "ok") {
                const billing = await Jobin.Business.getBillingInfo(
                    business.result
                );
                if (billing.status === "ok") {
                    dispatch({
                        type: "BUSINESS_DETAIL",
                        promotion:currentSession.contractor?.promotions.filter(promo=>props.match.params.businessId==promo.business).pop(),
                        business: business.result,
                        billing: billing.result
                    });
                }
                setLoading(false);
            } else {
                props.history.goBack();
            }
        }
        getBusiness();

        // unmount
        return () => {
            dispatch({
                type: "BUSINESS_DETAILS_RESET",
                business: initialState.businessDetails.business,
                billing: initialState.businessDetails.billing,
                jobs: initialState.businessDetails.jobs,
                jobsLoading: initialState.businessDetails.jobsLoading,
                bills: initialState.businessDetails.bills,
                billsLoading: initialState.businessDetails.billsLoading,
                billsTotal: initialState.businessDetails.billsTotal,
                id: initialState.businessDetails.id,
                startDate: initialState.businessDetails.filters.startDate,
                endDate: initialState.businessDetails.filters.endDate,
                provinces: initialState.businessDetails.filters.provinces,
                services: initialState.businessDetails.filters.services,
                month: initialState.businessDetails.filters.month,
            });
        };
    }, []);

    if (loading) {
        return (
            <section style={style.loadingContainer}>
                <Loading />
            </section>
        );
    } else {
        return (
            <section>
                <NavBar
                    history={props.history}
                    location={props.location}
                    title={"Detalle"}
                />
                <div className="detailContainer">
                    <Details
                        history={props.history}
                        location={props.location}
                    />
                    <Jobs history={props.history} location={props.location} />
                    {agent.dept != "ConstructionCompany" && <Billing
                        history={props.history}
                        location={props.location}
                    />}
                </div>
                {agent.dept != "ConstructionCompany" && <Footer />}
            </section>
        );
    }
}
