import React, { useState } from "react";

import HistoryContainer from "./HistoryContainer";
import AddNote from "./AddNote";
import { useStateValue } from "../../../../state/context";

import "../../../../styles/search/detail/job-detail-history.css";

export default function HistoryColumn() {
    const [{user}] = useStateValue();

    const shouldShowAddNote = () => {
        return ((!user?.isMediator && !user?.business) || user?.isConstructionCompany);
    };

    return (
        <div className="historyColumn">
            {shouldShowAddNote() && <AddNote />}
            <HistoryContainer />
        </div>
    );
}
