import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";

import { SearchBar } from "jobin-ui-components";
import FilterCustom from "./FilterCustom";

const fuseOptions = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["name"]
};

export default function FilterMultipleSelection(props) {
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState(props.title);
    const [query, setQuery] = useState("");
    const [filtered, setFiltered] = useState(false);
    const [items, setItems] = useState([]);
    const [itemsIsArr, setItemsIsArr] = useState(true);

    const styles = {
        nestedTitle: {
            cursor: "pointer",
            verticalAlign: "middle",
            ...props.style
        },
        nestedButton: {
            width: 12,
            height: 12,
            marginLeft: 8,
            verticalAlign: "middle",
            ...props.style
        },
        nestedButtonRotated: {
            width: 12,
            height: 12,
            marginLeft: 8,
            verticalAlign: "middle",
            transform: "rotate(90deg)",
            ...props.style
        },
        nestedList: {
            marginTop: 8,
            ...props.style
        }
    };
    useEffect(()=>{
        if(!props.isKpis && props.isClearFilters){
            clearFilter()
        }
    },[props.isClearFilters])
   
    const clearFilter = ()=>{
        setFiltered(false);
        setItems(prevItems => {
            return prevItems.map((item) => {
                if(!item.parent){
                    let noneTemp = {
                        ...item,
                        selected: false
                    };
                    return noneTemp;
                }else{
                    let noneTepm = {
                        ...item,
                        items:item.items.map(subItem => {
                            let tempSubItem =   {
                                ...subItem,
                                selected: false
                            };
                            return tempSubItem
                        })
                    }
                    return noneTepm
                }
            })
        })
        setTitle(props.title)
        props.settingClearFilters(false)
    }

    const NestedButton = collapsed => {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" style={collapsed.collapsed ? styles.nestedButton : styles.nestedButtonRotated}>
                <defs>
                    <path
                        id="nestedA"
                        d="M9 4c.256 0 .512.098.707.293l7 7a.999.999 0 0 1 0 1.415l-7.071 7.07a.999.999 0 1 1-1.414-1.413L14.586 12 8.293 5.708A.999.999 0 0 1 9 4"
                    />
                </defs>
                <g fill="none" fillRule="evenodd">
                    <mask id="nestedB" fill="#fff">
                        <use xlinkHref="#nestedA" />
                    </mask>
                    <g fill="#b0b0b0" mask="url(#nestedB)">
                        <path d="M0 0h24v24H0z" />
                    </g>
                </g>
            </svg>
        );
    };

    useEffect(() => {

        let items = props.items;
        
        if(items && items.length>0){
            setItems(items);
            const innerNodes = items.filter(item => item.parent);
            setItemsIsArr(innerNodes.length === 0);

            let selected = flattenOptions(items).filter(item => item.selected);
            setFiltered(selected.length > 0);

            if (selected.length === 0){ 
                setTitle(props.title);
            }else if (selected.length === items.length - 1){
                 setTitle(props.title + " - Todas");
            }else{ 
                setTitle(props.title + " - " + selected.length);
            }
        }
        setLoading(false);
    }, [props.items]);

    const optionSelected = item => {
        if (item.id === "all") {
            setTitle(props.title + " - Todas");
            setFiltered(true);
            setItems(prevItems => {
                const tempArray = [];
                const all = prevItems.map((item, idx) => {
                    // for nested objects
                    if (item.items) {
                        const nested = item.items.map(innerItem => {
                            let innerTemp = {
                                ...innerItem,
                                selected: true
                            };
                            if (idx === 0) {
                                innerTemp.id = "none";
                                innerTemp.name = "Ninguna";
                            } else {
                                tempArray.push(innerTemp);
                            }
                            return innerTemp;
                        });
                        let allTemp = {
                            ...item,
                            items: nested,
                            collapsed: false
                        };
                        return allTemp;
                    }
                    // for regular objects
                    else {
                        let allTemp = {
                            ...item,
                            selected: true
                        };
                        if (idx === 0) {
                            allTemp.id = "none";
                            allTemp.name = "Ninguna";
                        } else {
                            tempArray.push(allTemp);
                        }
                        return allTemp;
                    }
                });
                if (!loading && !props.isClearFilters) {
                    if(props.itemData != null){
                        props.selectionChange(tempArray,props.itemData,props.type);
                    }else if(!props.isClearFilters){
                        props.selectionChange(tempArray);
                    }
                }
                return all;
            });
        } else if (item.id === "none") {
            setTitle(props.title);
            setFiltered(false);
            props.selectionChange([]);
            setItems(prevItems => {
                return prevItems.map((item, idx) => {
                    // for nested objects
                    if (item.items) {
                        const nested = item.items.map(innerItem => {
                            let innerTemp = {
                                ...innerItem,
                                selected: false
                            };
                            if (idx === 0) {
                                innerTemp.id = "all";
                                innerTemp.name = "Todas";
                            }
                            return innerTemp;
                        });
                        let allTemp = {
                            ...item,
                            items: nested,
                            collapsed: false
                        };
                        return allTemp;
                    }
                    // for regular objects
                    else {
                        let noneTemp = {
                            ...item,
                            selected: false
                        };
                        if (idx === 0) {
                            noneTemp.id = "all";
                            noneTemp.name = "Todas";
                        }
                        return noneTemp;
                    }
                });
            });
        } else {
            changeItemSelection(item);
        }
    };

    const flattenOptions = items => {
            let flattenedItems = [];
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.parent) {
                    flattenedItems = flattenedItems.concat(flattenOptions(item.items));
                } else {
                    flattenedItems.push(item);
                }
            }
            return flattenedItems;
    };

    const changeItemSelection = item => {
        item.selected = !item.selected;
        let selected = flattenOptions(items).filter(item => item.selected);
        setFiltered(selected.length > 0);
        if(props.itemData != null && !props.isClearFilters){
            props.selectionChange(selected,props.itemData,props.type);
        }else if( !props.isClearFilters){
            props.selectionChange(selected);
        }

        if (selected.length === 0) setTitle(props.title);
        else if (selected.length === items.length - 1) setTitle(props.title + " - Todas");
        else setTitle(props.title + " - " + selected.length);
    };

    const toggleItems = item => {
        item.collapsed = !item.collapsed;
        setItems(prevItems => {
            return prevItems.map(pItem => {
                if ("collapsed" in pItem) {
                    if (pItem.parent === item.parent) return item;
                    else return pItem;
                } else return pItem;
            });
        });
    };

    const renderMenu = () => {
        const fuse = new Fuse(items, fuseOptions);
        const data = query ? fuse.search(query) : items;
        return (
            <div className="items-filter">
                <SearchBar
                    style={{ height: 36, width: 180 }}
                    styleinput={{ height: 36 }}
                    placeholder={"Filtrar"}
                    onChange={e => setQuery(e.target.value)}
                    value={query}
                />
                <div className="options">
                    <ul>
                        {data.map(item => {
                            return (
                                <li key={`${item.name}-${item.id}`}>
                                    <label htmlFor={item.id}>
                                        <input
                                            id={item.id}
                                            type="checkbox"
                                            className="native-hidden"
                                            onChange={()=>optionSelected(item)}
                                        />
                                        <svg width="24" height="24" viewBox="0 0 24 24">
                                            <g fill="none" fillRule="evenodd">
                                                <path d="M0 0h24v24H0z" />
                                                {item.selected ? (
                                                    <React.Fragment>
                                                        <rect width="22" height="22" x="1" y="1" fill="#2543FF" rx="2" />
                                                        <path
                                                            fill="#FFF"
                                                            d="M9.615 14.279L7.742 12.36a1.002 1.002 0 0 0-1.443 0 1.06 1.06 0 0 0 0 1.477l2.594 2.656a1.002 1.002 0 0 0 1.443 0l6.165-6.311a1.06 1.06 0 0 0 0-1.477 1.002 1.002 0 0 0-1.443 0L9.615 14.28z"
                                                        />
                                                    </React.Fragment>
                                                ) : (
                                                    <rect width="21" height="21" x="1.5" y="1.5" stroke="#444444" rx="2" />
                                                )}
                                            </g>
                                        </svg>
                                        <span>{item.name}</span>
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                    {props.advise && (<p>{props.advise}</p>)}
                </div>
            </div>
        );
    };

    const renderNestedMenu = () => {
        const data = items.map(item => {
            if (item.parent && query) {
                // fully clone without mutations
                let temp = JSON.parse(JSON.stringify(item));
                const fuse = new Fuse(item.items, fuseOptions);
                temp.items = fuse.search(query);
                temp.collapsed = false;
                return temp;
            } else return item;
        });
        return (
            <div className="items-filter">
                <SearchBar
                    style={{ height: 36, width: 180 }}
                    styleinput={{ height: 36 }}
                    placeholder="Filtrar"
                    onChange={e => setQuery(e.target.value)}
                    value={query}
                />
                <div className="options">
                    <ul>
                        {data.map((item, index) => {
                            return item.items ? (
                                <li key={`${item.parent}-${index}`}>
                                    <span style={styles.nestedTitle} onClick={() => toggleItems(item)}>
                                        {item.parent}
                                        <NestedButton collapsed={item.collapsed} />
                                    </span>
                                    {!item.collapsed && (
                                        <ul style={styles.nestedList}>
                                            {item.items.length > 0
                                                ? item.items.map(item => {
                                                      return (
                                                          <li key={`${item.name}-${item.id}`}>
                                                              <label htmlFor={item.id}>
                                                                  <input
                                                                      id={item.id}
                                                                      type="checkbox"
                                                                      className="native-hidden"
                                                                      onChange={() => optionSelected(item)}
                                                                  />
                                                                  <svg width="24" height="24" viewBox="0 0 24 24">
                                                                      <g fill="none" fillRule="evenodd">
                                                                          <path d="M0 0h24v24H0z" />
                                                                          {item.selected ? (
                                                                              <React.Fragment>
                                                                                  <rect
                                                                                      width="22"
                                                                                      height="22"
                                                                                      x="1"
                                                                                      y="1"
                                                                                      fill="#2543FF"
                                                                                      rx="2"
                                                                                  />
                                                                                  <path
                                                                                      fill="#FFF"
                                                                                      d="M9.615 14.279L7.742 12.36a1.002 1.002 0 0 0-1.443 0 1.06 1.06 0 0 0 0 1.477l2.594 2.656a1.002 1.002 0 0 0 1.443 0l6.165-6.311a1.06 1.06 0 0 0 0-1.477 1.002 1.002 0 0 0-1.443 0L9.615 14.28z"
                                                                                  />
                                                                              </React.Fragment>
                                                                          ) : (
                                                                              <rect
                                                                                  width="21"
                                                                                  height="21"
                                                                                  x="1.5"
                                                                                  y="1.5"
                                                                                  stroke="#444444"
                                                                                  rx="2"
                                                                              />
                                                                          )}
                                                                      </g>
                                                                  </svg>
                                                                  <span>{item.name}</span>
                                                              </label>
                                                          </li>
                                                      );
                                                  })
                                                : ""}
                                        </ul>
                                    )}
                                </li>
                            ) : (
                                <li key={`${item.name}-${item.id}`}>
                                    <label htmlFor={item.id}>
                                        <input
                                            id={item.id}
                                            type="checkbox"
                                            className="native-hidden"
                                            onChange={() => optionSelected(item)}
                                        />
                                        <svg width="24" height="24" viewBox="0 0 24 24">
                                            <g fill="none" fillRule="evenodd">
                                                <path d="M0 0h24v24H0z" />
                                                {item.selected ? (
                                                    <React.Fragment>
                                                        <rect width="22" height="22" x="1" y="1" fill="#2543FF" rx="2" />
                                                        <path
                                                            fill="#FFF"
                                                            d="M9.615 14.279L7.742 12.36a1.002 1.002 0 0 0-1.443 0 1.06 1.06 0 0 0 0 1.477l2.594 2.656a1.002 1.002 0 0 0 1.443 0l6.165-6.311a1.06 1.06 0 0 0 0-1.477 1.002 1.002 0 0 0-1.443 0L9.615 14.28z"
                                                        />
                                                    </React.Fragment>
                                                ) : (
                                                    <rect width="21" height="21" x="1.5" y="1.5" stroke="#444444" rx="2" />
                                                )}
                                            </g>
                                        </svg>
                                        <span>{item.name}</span>
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    };
    return (
        <FilterCustom
            styleMenu={props.styleMenu}
            style={props.style}
            type={props.type}
            isKpis={props.isKpis? "iskpis":""}
            title={title}
            filtered={filtered}
            styleButton={props.styleButton}
            idElement={props.idElement}
            menu={itemsIsArr ? renderMenu() : renderNestedMenu()}
        />
    );
}
