import React from "react";
import { useStateValue } from "../../../../state/context";

import PaymentsContainer from "./PaymentsContainer";
import JoberContainer from "./JoberContainer";
import ClientContainer from "./ClientContainer";

import "../../../../styles/search/detail/job-detail-info.css";
import IncidentsContainer from "./IncidentsContainer";
import OpsNotificationContainer from "./OpsNotificationContainer";

export default function InfoColumn(props) {
    const [{ user,jobDetails,agent }] = useStateValue();

    return (
        <div className="infoColumn">
            {!user?.isGoldWoller && !user?.isMediator &&(<ClientContainer />)}
            {!user?.isMediator && ( <JoberContainer history={props.history} location={props.location} />)}
            {(jobDetails.fixedInfo || jobDetails.transportInfo) && agent?.dept !== "ConstructionCompany" && (
                <PaymentsContainer fetchData={props.fetchData} />
            )}
            {!user?.isGoldWoller && !user.isB2b && user?.permissions?.job?.create && !user?.isMediator && (
                <OpsNotificationContainer></OpsNotificationContainer>
            )}
            {!user?.isGoldWoller && !user.isB2b && user?.permissions?.job?.create && (
                <IncidentsContainer mediator={user?.isMediator}/>
            )}
        </div>
    );
}
