import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { Loading } from 'jobin-ui-components'
import { useStateValue } from "../../../../state/context";
import {
    changeRequestedAssistance,
    closeAuctionJob,
    getUserData,
    resetAJob
} from "../../../../utils/ParserUtilities";

import InfoCell from "../InfoCell";
import JoberCell from "./JoberCell";
import Button from "../../../button/Button";
import ButtonEmpty from "../../../button/ButtonEmpty";
import MatchJobWollerModal from "../../../modal/MatchJobWollerModal";
import SelectDateModal from "../../../modal/SelectDateModal";
import JoberPriceModal from "../../../modal/JoberPriceModal";
import { apiId, jsKey, urlServer } from '../../../../constants/credentials';
import Parse from 'parse';

import "../../../../styles/search/detail/job-detail-info.css";
import AlertModal from "../../../modal/Alert";

export default function JoberContainer(props) {
    const [{ jobDetails, user, agent }, dispatch] = useStateValue();

    const [joberPrice, setJoberPrice] = useState(0.0);
    const [appointment, setAppointment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showSelectDateModal, setShowSelectDateModal] = useState(false);
    const [joberModalVisible, setJoberModalVisible] = useState(false);
    const [showJoberPriceModal, setShowJoberPriceModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isAssignable, setIsAssignable] = useState(true)


    useEffect(() => {
        let appointment = null;
        if (jobDetails.fixedInfo) {
            appointment = jobDetails.fixedInfo.get("Appointment");
            setJoberPrice(jobDetails.fixedInfo.get("JoberPrice"));
        } else if (jobDetails.transportInfo) {
            appointment = jobDetails.transportInfo.get("DeliveryDate");
            setJoberPrice(jobDetails.transportInfo.get("JoberPrice"));
        }

        if (appointment) {
            setAppointment(appointment.toString());
        }
        setLoading(false);
        allowAssign()
    }, []);

    const _isDisabledAssignation = () => {
        if (user?.isConstructionCompany) {
            return jobDetails.job.get("State") === "draft" || jobDetails.readMode;
        }

        return jobDetails.readMode;
    }

    const closeJob = async () => {
        let confirm = window.confirm('¿Seguro que desea cerrar la subasta?');
        if (confirm == true) {
            let pathname = window.location.href.split('/');
            try {
                var res = await closeAuctionJob(pathname[pathname.length - 1])
                window.alert('Encargo cerrado correctamente!');
            } catch (error) {
                console.error(error)
                window.alert('El encargo no se ha podido cerrar!');
            }
        }
    }

    const deleteWollerFromJob = async (item) => {
        let pathname = window.location.href.split('/');

        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let data = {
            jobId: pathname[pathname.length - 1],
            joberId: item.id
        }

        let res = await Parse.Cloud.run("deleteWollerFromJob", data);
        window.location.reload();
    }

    const asignJober = async jober => {
        setJoberModalVisible(false);
        setLoading(true);
        if (jobDetails.transportInfo) {
            const result = await Jobin.Job.sendToTransit(
                [jobDetails.transportInfo.get("ID")],
                jober
            );
            if (result.status === "ok") {
                await jobDetails.getHistoryOfJob(jobDetails.job);
                dispatch({
                    type: "JOB_DETAILS_JOB",
                    jobersArray: [jober],
                    job: await jobDetails.job.fetch(),
                    transportInfo: jobDetails.transportInfo && await jobDetails.transportInfo.fetch(),
                    fixedInfo: jobDetails.fixedInfo && await jobDetails.fixedInfo.fetch(),
                });
            } else {
                alert(result.result);
            }
        } else if (jobDetails.fixedInfo) {
            const result = await Jobin.Jober.asignToJob(
                jober,
                jobDetails.job
            );
            if (result.status === "ok") {
                dispatch({
                    type: "JOB_DETAILS_JOB",
                    jobersArray: [jober],
                    job: await jobDetails.job.fetch()
                });
            } else {
                alert(result.result);
            }
        } else {
            let goldUser;
            goldUser = await getUserData(user.goldId);
            if (user?.isGoldWoller) {
                let subVert = jobDetails.job.get("Subvertical")
                let price = jobDetails.job.get("JobLeadPrice") ? jobDetails.job.get("JobLeadPrice") : subVert.get("nLeadsPerPC")?.default
                if (goldUser.get("nJobs") < price) {
                    alert('No posees saldo suficiente para pagar este servicio');
                    setLoading(false);
                    setShowConfirmModal(false)
                    return null
                }
                setShowConfirmModal(false)
            }

            const result = await Jobin.Jober.asignToJob(
                user?.isGoldWoller ? goldUser : jober,
                jobDetails.job
            );
            if (result.status === "ok") {
                const tempArray = [...jobDetails.jobersArray, user?.isGoldWoller ? goldUser : jober];
                dispatch({
                    type: "JOB_DETAILS_JOB",
                    jobersArray: tempArray,
                    job: await jobDetails.job.fetch()
                });
            } else {
                alert(result.result);
            }
        }
        await jobDetails.getHistoryOfJob(jobDetails.job);
        setLoading(false);
    };

    const saveDate = async date => {
        const result = await Jobin.Job.changeAppointment(
            jobDetails.job,
            date
        );
        if (result.status === "ok") {
            await jobDetails.getHistoryOfJob(jobDetails.job);
            dispatch({
                type: "JOB_DETAILS_JOB",
                transportInfo: jobDetails.transportInfo && await jobDetails.transportInfo.fetch(),
                fixedInfo: jobDetails.fixedInfo && await jobDetails.fixedInfo.fetch(),
            });
            setAppointment(date.toString());
            setShowSelectDateModal(false);
        } else {
            setShowSelectDateModal(false);
            alert(result.result);
        }
    };

    const savePrice = async price => {
        const result = await Jobin.Job.changeJoberPrice(
            jobDetails.job,
            price
        );
        if (result.status === "ok") {
            setJoberPrice(price);
            setShowJoberPriceModal(false);
        } else {
            alert(result.result);
        }
    };

    const goToJoberProfile = id => {
        props.history.push({
            pathname: "/user/" + id,
            state: {
                inner: true
            }
        });
    }
    const allowAssign = () => {
        if (!user?.isGoldWoller && user?.isConstructionCompany) {
            return setIsAssignable(false)
        } else if (jobDetails.job.get("State") === "abierto" && user.permissions && user.permissions.job.edit) {
            return setIsAssignable(false)
        } else {
            return setIsAssignable(true)
        }
    }
    const handleRequestAssistance = async () => {
        try {
            await resetAJob(jobDetails.job.id);
            await changeRequestedAssistance(jobDetails.job.id);
            window.location.reload();
        } catch (error) {
            console.error("Error al solicitar asistencia:", error);
        }
    };

    if (loading) {
        return (
            <section className="infoContainer">
                <label className="title">Datos del Profesional</label>
                <Loading />
            </section>
        );
    } else {
        if (jobDetails.transportInfo || jobDetails.fixedInfo) {
            return (
                <section className="infoContainer">
                    {showSelectDateModal && (
                        <SelectDateModal
                            isVisible={showSelectDateModal}
                            onClose={() => setShowSelectDateModal(false)}
                            saveDate={saveDate}
                        />
                    )}
                    {joberModalVisible && (
                        <MatchJobWollerModal
                            isVisible={joberModalVisible}
                            onClose={() => setJoberModalVisible(false)}
                            asignJober={asignJober}
                            job={jobDetails.job}
                            leadPrice={jobDetails.job.get("JobLeadPrice") ? jobDetails.job.get("JobLeadPrice") : undefined}
                        />
                    )}
                    {showJoberPriceModal && (
                        <JoberPriceModal
                            isVisible={showJoberPriceModal}
                            onClose={() => setShowJoberPriceModal(false)}
                            priceMax={jobDetails.job.get("PriceMax")}
                            savePrice={savePrice}
                        />
                    )}
                    <div style={{ display: "flex", flex: 1 }}>

                        <label className="title">Datos del Woller</label>
                        {jobDetails.jobersArray[0] &&
                            jobDetails.fixedInfo &&
                            (jobDetails.job.get("State") === "abierto" || jobDetails.job.get("State") === "full") && (

                                <ButtonEmpty
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "flex-end"
                                    }}
                                    buttonText={user.permissions && user.permissions.job.edit ? "Cambiar Woller" : null}
                                    onClick={user.permissions && user.permissions.job.edit ? () => setJoberModalVisible(true) : null}
                                    disabled={jobDetails.readMode}
                                />
                            )}
                    </div>
                    <div>
                        {jobDetails.jobersArray.length > 0 ? (
                            <JoberCell
                                jober={jobDetails.jobersArray[0]}
                                onClick={!jobDetails.readMode ? () => goToJoberProfile(jobDetails.jobersArray[0].id) : null}
                            />
                        ) : (
                            <section
                                style={{
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                            >
                                <label>
                                    {jobDetails.transportInfo
                                        ? "Para asignar un Profesional este pedido debe enviarse a reparto"
                                        : "Aún no hay un Profesional asignado a esta solicitud"}
                                </label>
                                <Button
                                    style={{ marginTop: 16, color: "var(--coral)" }}
                                    buttonText={
                                        jobDetails.transportInfo
                                            ? "Enviar a reparto"
                                            : "Asignar Profesional"
                                    }
                                    onClick={() => setJoberModalVisible(true)}
                                    disabled={_isDisabledAssignation() || isAssignable}
                                />
                                {agent.dept == "ConstructionCompany" && <Button
                                    style={{ marginTop: 16, color: "var(--coral)" }}
                                    buttonText="Solicitar asistencia a la red de Wolly"
                                    onClick={handleRequestAssistance}
                                    disabled={_isDisabledAssignation()}
                                />}
                            </section>
                        )}
                        <div className="detailContainer">
                            <InfoCell
                                title={
                                    jobDetails.transportInfo
                                        ? "Fecha de entrega"
                                        : "Fecha de la cita"
                                }
                                data={
                                    appointment
                                        ? Jobin.Date.toLargeFormat(
                                            new Date(appointment)
                                        ).result
                                        : null
                                }
                                buttonText={
                                    appointment ? null : user.permissions && !user.permissions.job.edit ? null : jobDetails.transportInfo ? "Añadir fecha de entrega" : "Crear cita"

                                }
                                onClick={() => {
                                    if (jobDetails.readMode || (user.permissions && !user.permissions.job.edit)) return null
                                    else if (["pending", "draft"].includes(jobDetails.job.get("State"))) setShowSelectDateModal(true);
                                    else alert("Antes de crear una cita, el cliente debe pagar el servicio");
                                }}
                                optionAction={
                                    !jobDetails.readMode
                                        ? () => {
                                            if (jobDetails.job.get("State") === "pending" || jobDetails.job.get("State") === "full") {
                                                setShowSelectDateModal(true);
                                            } else {
                                                alert("Antes de crear una cita, el cliente debe pagar el servicio");
                                            }
                                        }
                                        : null
                                }
                                optionText={user.permissions && user.permissions.job.edit && jobDetails.job && (jobDetails.job.get("State") === "full" || jobDetails.job.get("State") === "abierto") ?
                                    "Editar"
                                    : null
                                }
                                halfLeft
                            />
                            <p>{!(jobDetails.job.get("State") === "signaturePending" || jobDetails.job.get("State") === "finalizado" || jobDetails.job.get("State") === "review")}</p>
                            {user?.permissions?.job?.create && (!user.isConstructionCompany || jobDetails.readMode) && (
                                <InfoCell
                                    title="Precio del Profesional"
                                    data={
                                        joberPrice
                                            ? (joberPrice).toFixed(2) + "€ (" + (joberPrice * 1.21).toFixed(2) + "€ con IVA)"
                                            : "0€ (0€ con IVA)"
                                    }
                                    optionAction={!jobDetails.readMode && user.permissions && user.permissions.job.edit ? () =>
                                        setShowJoberPriceModal(true) : null
                                    }
                                    optionText={!(jobDetails.job.get("State") === "signaturePending" || jobDetails.job.get("State") === "finalizado" || jobDetails.job.get("State") === "review") && user.permissions && user.permissions.job.edit ? "Editar" : null}
                                    halfRight
                                />
                            )}
                        </div>
                    </div>
                </section>
            );
        } else {
            return (
                <section className="infoContainer">
                    {joberModalVisible && (
                        <MatchJobWollerModal
                            isVisible={joberModalVisible}
                            onClose={() => setJoberModalVisible(false)}
                            asignJober={asignJober}
                            job={jobDetails.job}
                            leadPrice={jobDetails.job.get("JobLeadPrice") ? jobDetails.job.get("JobLeadPrice") : undefined}
                        />
                    )}
                    {showConfirmModal && (
                        <AlertModal
                            isVisible={showConfirmModal}
                            onClose={() => {
                                setShowConfirmModal(false);
                            }}
                            title={jobDetails.job.get("Title")}
                            subtitle="¿Seguro que deseas comprar este trabajo?"
                            buttonText="Comprar"
                            onClick={asignJober}
                        />
                    )}
                    <div style={{ display: "flex", flex: 1 }}>
                        <label className="title">Wollers inscritos</label>
                        {!user.isB2b && (jobDetails.job.get("State") === "abierto" || (jobDetails.job.get("TotalSlots") - jobDetails.job.get("nJober") - 1) < jobDetails.job.get("TotalSlots")) && user?.permissions?.job?.create && (
                            <ButtonEmpty
                                style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "flex-end"
                                }}
                                buttonText={!user?.isGoldWoller ? "Asignar Wollers" : "Asignarme"}
                                onClick={() => { user?.isGoldWoller ? setShowConfirmModal(true) : setJoberModalVisible(true) }}
                            />
                        )}
                    </div>
                    {jobDetails.jobersArray.length > 0 ? (
                        jobDetails.jobersArray.map((item, index) => {
                            if (user?.isGoldWoller) {
                                return user?.goldId == item.id && (
                                    <JoberCell
                                        key={"jober" + index}
                                        jober={item}
                                        onClick={() => goToJoberProfile(item.id)}
                                        onDelete={() => deleteWollerFromJob(item)}
                                    />
                                );
                            } else {
                                return (<JoberCell
                                    key={"jober" + index}
                                    jober={item}
                                    onClick={() => goToJoberProfile(item.id)}
                                    onDelete={() => deleteWollerFromJob(item)}
                                />)
                            }

                        })
                    ) : (
                        <section>
                            <label>
                                Aún no hay Wollers inscritos en esta solicitud
                            </label>
                        </section>
                    )}
                    <div>
                        {!user?.isGoldWoller && user?.permissions?.user?.edit && (<button
                            style={{
                                backgroundColor: '#202020',
                                border: 'none',
                                borderRadius: '4px',
                                color: 'rgb(255, 226, 53)',
                                padding: '12px',
                                marginRight: '5px',
                                marginTop: '5px'
                            }}
                            onClick={closeJob}
                        >Cerrar subasta</button>)}

                    </div>
                </section>
            );
        }
    }
}
