import React from "react";
import Jobin from "jobin-client";
import { Switch, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { apiId, jsKey, urlServer } from "./constants/credentials";
import { alteredState } from "./state/alteredState";
import { reducer } from "./state/reducer";
import { StateProvider } from "./state/context";
import { getCookie } from "./utils/cookie";
import LogIn from "./organisms/LogIn";
import Dashboard from "./organisms/Dashboard";

import "./styles/grid12.css";
import "./styles/normalize.css";
import "./styles/wolly.css";

const App = () => {
    Jobin.initServer(apiId, jsKey, urlServer);
    const agent = JSON.parse(
        getCookie("agent") ? decodeURIComponent(getCookie("agent")) : null
    );
    Jobin.initToken(agent ? agent.token : undefined);

    return (
        <CookiesProvider>
            <StateProvider initialState={alteredState} reducer={reducer}>
                <Switch>
                    <Route path="/login" component={LogIn} />
                    <Route path="/" component={Dashboard} />
                </Switch>
            </StateProvider>
        </CookiesProvider>
    );
};

export default App;
