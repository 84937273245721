import React, { useState, useEffect, useRef } from "react";
import Jobin from "jobin-client";
import moment from "moment";
import { useStateValue } from "../../../state/context";
import { provinceArr } from "../../../constants/provinces";
import { jobTypeArr } from "../../../constants/user/jobTypes";
import fetchServices from "../../../constants/services";

import { SearchBar } from "jobin-ui-components";
import { getFiltredJobs } from "../../../utils/ParserUtilities";
import CSVExport from "../../../utils/csvExport";
import FilterMultipleSelection from "../../filter/FilterMultipleSelection";
import FilterSingleSelection from "../../filter/FilterSingleSelection";
import FilterDate from "../../filter/FilterDate";
import { apiId, jsKey, urlServer  } from '../../../constants/credentials';
import Parse from "parse";

import {formatCreatedAtCSV, formatPostalCodeToProvince} from '../../../utils/formatter'

export default function JobSearchNav(props) {
    const [{ user, userDetails,currentSession }, dispatch] = useStateValue();
    const [data] = useStateValue();
    const [guildArr, setGuildArr] = useState([]);
    const [jobId, setJobId] = useState(undefined);

    const csvRef = useRef(null);

    const style = {
        searchNav: {
            paddingRight: 24,
            paddingLeft: 24,
            paddingTop: 24,
            borderBottom: "2px solid var(--soft-grey)"
        },
        filterView: {
            paddingTop: 8,
            paddingBottom: 12
        }
    };

    useEffect(() => {
        async function fetchAsync() {
            if(!user?.isGoldWoller){

                setGuildArr(await fetchServices(userDetails.filters.services));
            }else{
                
                setGuildArr(await fetchServices(user.goldGuilds,true));
            }
        }
        fetchAsync();
        fetchJobsByDate(undefined)
       
    }, []);

    const getJobs = async ( jobType = null, startDate = null, endDate = null, provinces = null, guilds = null,  jobId = null) => {
        dispatch({
            type: "USER_DETAILS_JOBS_LOADING",
            jobsLoading: true
        });

        const jobs = await getFiltredJobs(
            user.isGoldWoller ? currentSession?.wollerData?.id:userDetails.user.id,
            jobType,
            startDate,
            endDate,
            provinces,
            guilds,
            jobId,
            100
           
        );
        dispatch({
            type: "USER_DETAILS",
            jobs: jobs
        });
        dispatch({
            type: "USER_DETAILS_JOBS_LOADING",
            jobsLoading: false
        });
    };

    const fetchJobsByDate = async date => {
        getJobs(
            userDetails.filters.id == " " ? "lead":userDetails.filters.id,
            date && date.startDate
                ? date.startDate._isAMomentObject
                    ? date.startDate.format()
                    : date.startDate
                : undefined,
            date && date.endDate
                ? date.endDate._isAMomentObject
                    ? date.endDate.format()
                    : date.endDate
                : undefined,
            userDetails.filters.provinces.length > 0
                ? userDetails.filters.provinces.map(province => province.id)
                : undefined,
            userDetails.filters.services.length > 0
                ? userDetails.filters.services.map(guild => guild.name)
                : undefined,
                null
        );
        dispatch({
            type: "USER_DETAILS_FILTERS",
            startDate:
                date && date.startDate
                    ? date.startDate._isAMomentObject
                        ? date.startDate
                        : moment(date.startDate)
                    : undefined,
            endDate:
                date && date.endDate
                    ? date.endDate._isAMomentObject
                        ? date.endDate
                        : moment(date.endDate)
                    : undefined
        });
    };

    const fetchJobsByProvince = async selection => {
        getJobs(
            userDetails.filters.id == " " ? "lead":userDetails.filters.id,
            userDetails.filters.startDate
                ? userDetails.filters.startDate._d
                : undefined,
            userDetails.filters.endDate
                ? userDetails.filters.endDate._d
                : undefined,
            selection.map(province => province.id),
            userDetails.filters.services.length > 0
                ? userDetails.filters.services.map(guild => guild.name)
                : undefined,
                null
        );
        dispatch({
            type: "USER_DETAILS_FILTERS",
            provinces: selection
        });
    };

    const fetchJobsByGuild = async selection => {
        getJobs(
            userDetails.filters.id == " " ? "lead":userDetails.filters.id,
            userDetails.filters.startDate
                ? userDetails.filters.startDate._d
                : undefined,
            userDetails.filters.endDate
                ? userDetails.filters.endDate._d
                : undefined,
            userDetails.filters.provinces.length > 0
                ? userDetails.filters.provinces.map(province => province.id)
                : undefined,
            selection.map(guild => guild.name),
        );
        dispatch({
            type: "USER_DETAILS_FILTERS",
            services: selection
        });
    };

    const fetchJobsById = async e => {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            getJobs(
                userDetails.filters.id == " " ? "lead":userDetails.filters.id,
                userDetails.filters.startDate
                    ? userDetails.filters.startDate._d
                    : undefined,
                userDetails.filters.endDate
                    ? userDetails.filters.endDate._d
                    : undefined,
                userDetails.filters.provinces.length > 0
                    ? userDetails.filters.provinces.map(province => province.id)
                    : undefined,
                userDetails.filters.services.length > 0
                    ? userDetails.filters.services.map(guild => guild.name)
                    : undefined,
                jobId !== "" ? jobId : undefined
            );
            dispatch({
                type: "USER_DETAILS_FILTERS",
                id: jobId
            });
        }
    };

    const fetchJobsByJobType = async selection => {
        
            getJobs(
                selection.id == " " ? "lead":selection.id,
                userDetails.filters.startDate
               ? userDetails.filters.startDate._d
               :  undefined,
               userDetails.filters.endDate
               ? userDetails.filters.endDate._d
               :  undefined,
           userDetails.filters.provinces.length > 0
               ? userDetails.filters.provinces.map(province => province.id)
               : undefined,
           userDetails.filters.services.length > 0
               ? userDetails.filters.services.map(guild => guild.name)
               : undefined,
                null
            );
            dispatch({
                type: "USER_DETAILS_FILTERS",
                id: selection.id
            });
        
    };

    const getJobsByWollerId = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();

        let csvData = {
            data: [],
            headers: [
                { label: "ID", key: "id" }, { label: "Título", key: "title" }, { label: "Descripción", key: "description" },
                { label: "Fecha de creación", key: "createdAt" }, { label: "Estado", key: "state" },
                { label: "Saldo", key: "nJober" }, { label: "Provincia", key: "postalCode" }, { label: "Dirección", key: "address" },
                { label: "TotalSlots", key: "totalSlots" }, { label: "Nivel", key: "level"}, { label: "Tipo", key: "jobType" }, 
                { label: "Precio", key: "jobLeadPrice" }, { label: "Gremio", key: "vertical"}, { label: "Teléfono", key: "phone"},
                { label: "Nombre", key: "name"}
            ]
        }

        let res = await Parse.Cloud.run("getJobsByWollerId", { userId: userDetails.user.id });
        res.map( not => {            
            const job = not?.get('Jobs');
            csvData.data.push( { 
                id: job?.id, title: job?.get('Title'), 
                description: job?.get('Description'),
                createdAt: formatCreatedAtCSV(job?.get('createdAt') ? job?.get('createdAt') : ""), 
                state: job?.get('State'), nJober: job?.get('nJober'),
                postalCode: formatPostalCodeToProvince(job?.get('PostalCode') ? job?.get('PostalCode')?.substring(0, 2) : ""),
                address: job?.get('Address').replaceAll(',',';'),
                totalSlots: job?.get('TotalSlots'),
                level: job?.get('Level'), jobType: job?.get('JobType'), 
                jobLeadPrice: job?.get('JobLeadPrice'), 
                vertical: job?.get("Type"),
                phone: not.get("ToUser")?.get("Phone"), 
                name: not.get("ToUser")?.get("Name")
            } );
        })

        return csvData;
    }

    // return data.user.permissions && data.user.permissions.user.edit ? (
    return data.user.permissions && (
        <div style={style.searchNav}>
            {
                props.biPro == false && (
                    <SearchBar
                        style={{ display: "flex", flex: 1 }}
                        placeholder="Buscar por ID"
                        onKeyPress={fetchJobsById}
                        onChange={e => setJobId(e.target.value)}
                    />
                )}
            
            <div style={style.filterView}>
                <FilterDate
                    style={{ marginRight: 4 }}
                    type="date"
                    title="Fecha de creación"
                    startDate={undefined}
                    endDate={undefined}
                    selectionChange={fetchJobsByDate}
                />
                <FilterMultipleSelection
                    style={{ marginRight: 4 }}
                    type="province"
                    title="Provincias"
                    items={provinceArr}
                    selectionChange={fetchJobsByProvince}
                />
                { props.biPro == false && (
                    <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="service"
                        title="Gremios"
                        items={guildArr}
                        selectionChange={fetchJobsByGuild}
                    />                
                    )
                }
                <FilterSingleSelection
                    style={{ marginRight: 4 }}
                    type="service"
                    title="Tipo de trabajo"
                    items={jobTypeArr}
                    selectionChange={fetchJobsByJobType}
                />
            </div>

                    <div>
                        <a ref={csvRef} style={{ display: "none" }} />
                        <CSVExport
                            children={"Descargar"}
                            getDataForCsv={getJobsByWollerId}
                        />
                    </div>

            
        </div>
    )
}
