import React, { useEffect, useState } from "react";

import MainModal from "./MainModal"
import AlertModal from "./Alert";
import RefuseServiceModal from "./RefuseServiceModal";

import { useStateValue } from "../../state/context";
import { formatCurrency } from "../../utils/formatter";
import { buyJobForWoller,uploadFilesFromJob } from "../../utils/ParserUtilities";
import Lottie from "react-lottie";


import moment from "moment";
import "moment/locale/es";


import * as cardSpinnerData from "../../assets/animations/card-spinner.json";
import "../../styles/modal/service-details-modal.css";
import wrench from "../../assets/icons/wrench.svg"
import {wollersFrontUrl} from "../../constants/credentials";
import ImageModal from "./ImageModal";



const ServiceDetails = ({onClose,isVisible,service,lowFounds,lowLevel,forOwn,handleBuyFromModal,jobCard}) => {
    
    const [{currentSession, user}, dispatch] = useStateValue();
    const [jobSelected, setJobSelected] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showRefuseServiceModal, setShowRefuseServiceModal] = useState(false);
    const [copyText, setCopyText] = useState("Compartir URL");
    const [photos ,setPhotos] = useState(service.images ? service.images:[]);
    const [loading ,setLoading] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false)
    const [imageSelected, setImageSelected] = useState(null)

    const thumbnail = {
        width:80,
        height:80,
        borderRadious:50,
        backgroundPosition:"center",
        backgroundSize:"cover",
        backgroundRepeat:"no-repeat",
        margin:10
    }
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cardSpinnerData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const handleBuy = async (service)=>{
        if(parseInt(currentSession.wollerData.get("Level")) < parseInt(service.level)){
            lowLevel(true)
        }else if(currentSession.wollerData.get("nJobs") < parseInt(service.price)){
            lowFounds(true)
        }else{
            setJobSelected(service)
            setShowConfirmModal(true)
        }
    }
    const asignWoller = async (serviceId) => {
        let buy = await  buyJobForWoller(serviceId,currentSession.wollerData.id)
        if (buy){
            setShowConfirmModal(false)
            handleBuyFromModal()
        } else  {
            alert(buy.error)
            setShowConfirmModal(false)
        }
    };

    const calculateSlots = (slots,takenSlots,serviceId)=>{
        let takenSlots_ = takenSlots 
        let slotsCalc = []
        for (let index = 0; index < parseInt(slots); index++) {
            if(takenSlots_ > 0){
                takenSlots_ = takenSlots_ - 1
                slotsCalc.push(<div key={"fullHelmet"+index+serviceId} className="helmet"><img src={require("../../assets/icons/Casco.png")} alt=""  /></div>)
            }else{
                slotsCalc.push(<div key={"emptyHelmet"+index+serviceId} className="empty"></div>)
            }
        }
        return slotsCalc
    }
    const handlePhotos = async (files,index)=>{
        let url = window.location.href.split('?');

        files = [...files]
        if(files.length > 1 ){
            let images = files.map(async (file)=>{
                if(file.size > 10000000) {
                    alert("El archivo seleccionado es muy grande!");
                 }else{
                     return  getFileData(file)
                 }
           })
           
           Promise.all(images).then(results => {
                let photosParsed = results.map((photo,index)=>{
                    let fileName = service.id + Math.floor(Math.random() * 90000) + 10000;
                    if(photo){
                        return {base64:photo,fileName:fileName};
                    }else{
                        return null
                    }
                })
                setPhotos(searches => searches.concat(photosParsed))
           })
       } else if(files.length === 1){
            let image =  await getFileData(files[0])
            let fileName = service.id + Math.floor(Math.random() * 90000) + 10000;
            if(index!== undefined){
                let newphoto = [...photos]
                newphoto[index] = {base64:image,fileName:fileName}
                setPhotos(newphoto)
                handleUploadPhotos(newphoto)
            }else{
                setPhotos([...photos, {base64:image,fileName:fileName}])
                handleUploadPhotos([...photos, {base64:image,fileName:fileName}])
            }
        }

    }
    const getFileData = (file) => {      
        return new Promise((resolve, reject) => {
            if (window.FileReader) {
                // great success! All the File APIs are supported.
                let reader = new FileReader();
            
                reader.onloadstart = () => {};
                reader.onloadend = e => {
                    // starting the image optmization process
                    let image = new Image();
                    image.onload = () => {
                        // Resize the image
                        let canvas = document.createElement("canvas"),
                            max_size = 550,
                            width = image.width,
                            height = image.height;
            
                        if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                        } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                        }
            
                        canvas.width = width;
                        canvas.height = height;
                        canvas.getContext("2d").drawImage(image, 0, 0, width, height); // this is the base64 img
            
                        let base64 = canvas.toDataURL("image/jpeg");
                        resolve(base64);
                    };
            
                    image.src = e.target.result;
                }
                reader.readAsDataURL(file);
            }; // read in the image file as a data URL.
            
        });
    };
    const handleUploadPhotos = (photos_)=>{
        setLoading(true)
        let upload = uploadFilesFromJob(photos_,service.id)
        Promise.all([upload]).then(()=>{
            setLoading(false)
        }).catch((error)=>{
            console.error(error)
            alert("Fallo al tratar de cargar documentación")
            setLoading(false)
        })
    }
    const fixedProcess = ()=>{
        window.open(wollersFrontUrl + `upload-photos?${service.id}?${currentSession.wollerData.id}`, "Diseño Web", "width=300, height=200")
    }
    const generateLink = ()=>{
        setCopyText("Copiado en el portapapeles")
        navigator.clipboard.writeText(wollersFrontUrl + `upload-photos?${service.id}?${currentSession.wollerData.id}`)
    }
    const handleRefuseServiceModalClose = async (rejected) => {
        setShowRefuseServiceModal(false);
        onClose(rejected);
    };
    return (
        <MainModal
            classNamesModal={{modal:"ServiceDetailsModal"}}
			isVisible={isVisible}
			onClose={()=>onClose()}
            center={true}
		>
            {showImageModal && <ImageModal
                isVisible={showImageModal}
                onClose={()=>setShowImageModal(false)}
                image={imageSelected}
            />}
            {showConfirmModal && (<AlertModal
                        isVisible={showConfirmModal}
                        onClose={() => setShowConfirmModal(false)}
                        title={service.title}
                        subtitle="¿Seguro que deseas comprar este trabajo?"
                        buttonText="Comprar"
                        onClick = {()=>{asignWoller(service.id)}}
                        classNamesModal={{modal:"outFoundsModal"}}
                    />
                )}
            {showRefuseServiceModal && (<RefuseServiceModal
                    isVisible={showRefuseServiceModal}
                    onClose={(rejected) => handleRefuseServiceModalClose(rejected)}
                    job={service}
                    title={service.title}
                    subtitle="¿Seguro que deseas rechazar este trabajo?"
                />
            )}
            <div className="service-details-wrapper">
                <div className="service-details-data-wrapper">
                    <div className={service?.level == 2? "service-details-row-wrapper title Plata":service?.level == 3 ? "service-details-row-wrapper title Oro":service?.level == 4 ? "service-details-row-wrapper title Platino":"service-details-row-wrapper title Bronce"}>
                        <img className="service-detail-vertical-img"  src={service.vertical ? service.vertical?.get("Icon")?.url() ? service.vertical?.get("Icon")?.url() : "" : service.verticalLogo._url}/>
                        <span className="service-detail-level">{service?.level == 2? "Plata -":service?.level == 3 ? "ORO -":service?.level == 4 ? "Platino -" :"Bronce -"}</span>
                        <p className="service-details-vertical"> {service.type}</p>
                        {service.jobState == "finalizado" && user?.isConstructionCompany && (<p className="service-details-vertical"> - {service.jobState}</p>)}
                    </div>

                    <div className="service-details-row-wrapper">
                        
                        <p className="service-details-title">{service.title}</p>
                    </div>
                    <div className="service-details-row-wrapper">
                        <img src={require("../../assets/icons/User.svg")} alt="" />
                        <p className="service-details-p">{service.createdBy ? service.createdBy?.get("Name") : service.clientName}</p>
                    </div>
                    <div className="service-details-row-wrapper">
                        <img src={require("../../assets/icons/MapPinLine.svg")} alt="" />
                        <p className="service-details-p">{service.address}</p>
                    </div>
                     <div className="service-details-row-wrapper">
                        <img  src={require("../../assets/icons/Phone.svg")} alt="" />
                        <p className="service-details-p">   {!service.createdBy && !forOwn? service.clientPhone :forOwn && service.createdBy?.get("Phone").length > 9 ? service.createdBy?.get("Phone").slice(0,9)+"***": forOwn ?  service.createdBy?.get("Phone").slice(0,6)+"***" :service.createdBy?.get("Phone")}</p>
                    </div>
                    <div className="service-details-row-wrapper">
                        <img src={require("../../assets/icons/Clock.svg")} alt="" />
                        <p className="service-details-p">Subido {moment(service?.createdAt).fromNow()}</p>
                    </div>
                    {
                        (service.business || service.businessLogo) && (
                            <div className="service-details-row-wrapper">
                                <img src={require("../../assets/icons/Suitcase.svg")} alt="" />
                                <img className="businessIcon" src={service.business ? service.business?.get("Logo")?.url() ? service.business?.get("Logo")?.url() : "": service.businessLogo ? service.businessLogo._url : ""}/>
                            </div>
                        )
                    }
                    {
                        (service.jobType == "fixed" && service.fixedPrice) && (
                            <div className="service-details-row-wrapper-fixed profit">
                                <span className="flofitTitle">Ganancia Directa:</span> <span className="profitData">{formatCurrency(service.fixedPrice)} + IVA</span>
                            </div>
                        )
                    }
                    <div className="buyAndSlots">

                        {forOwn && (service.jobType == "lead" || service.jobType== undefined) ? 
                            (<button onClick={()=>handleBuy(service)} className="service-details-buy-button">CONTACTAR POR {formatCurrency(service.jobLeadPrice) }</button>):
                        !forOwn && service.jobType == "fixed"  && service.jobState != "finalizado"? 
                            (
                                <>
                                    <button
                                        onClick={() => fixedProcess(service)}
                                        className="service-details-buy-button">INICIAR
                                        GESTIÓN
                                    </button>
                                    <button
                                        onClick={() => generateLink(service)}
                                        className="service-details-buy-button-clear">{copyText}</button>
                                    {user.isConstructionCompany &&<button
                                        onClick={() =>{
                                            setShowRefuseServiceModal(true);
                                        }}
                                        className="service-details-buy-button-clear">Rechazar</button>}
                                </>
                            )
                        :!forOwn && service.jobType == "fixed" && user?.isConstructionCompany && service.jobState == "finalizado"?
                        null
                        :
                            (<a href={`tel:${service.createdBy?.get("Phone")}`} className="service-details-buy-button">CONTACTAR</a>)
                        }
                        {(service.jobType == "lead" || service.jobType== undefined) && <div className="slotsContainer">
                                {service?.totalSlots == 1 ? 
                                    (
                                        <>
                                            <img className="oneSlot" src={require("../../assets/n1.png")} alt="" />
                                            <p>SERVICIO SIN COPMPETENCIA</p>
                                        </>
                                    ) :(
                                        <>
                                            {calculateSlots(service?.totalSlots,service?.takenSlots,service.id)}
                                        </>
                                )}
                        </div>}
                    </div>
                    
                    <div className="uploadPhotos">
                        {!forOwn && service.jobType == "fixed" && user?.isConstructionCompany && service.jobState == "finalizado"? 
                            (
                                <>
                                    <label for="photo" className="service-details-buy-button"> Añadir documentación</label>
                                    <input type="file"  id="photo" multiple  hidden onChange={(event)=>handlePhotos(event.target.files)}/>
                                    <div style={{display:"flex"}}>
                                        {photos && photos.map((image)=>(
                                            <div  style={{...thumbnail,backgroundImage:`url("${image.base64}")`}}  alt="" ></div>
                                        ))}
                                    </div>
                                </>
                            )
                        :null}
                    </div>
                    {
                        (service.jobType == "fixed" && service.jobState != "finalizado" && !user?.isConstructionCompany) && (
                            <div className="service-details-row-wrapper-fixed advice">
                                <img src={require("../../assets/icons/Warning.svg")} alt="" />
                                <p className="service-details-p-advice">Para recibir el pago, es obigatorio completar todos los pasos, incluidas las fotos del trabajo terminado y la firma de conformidad.</p>
                            </div>
                        )
                    }                
                    <p className="service-details-description">Descripción detallada</p>
                    <p className="service-details-description-p">{service.description}</p>
                    <div className="service-details-wollyShop"  onClick={() => window.open('https://www.shop.wollyhome.com/', '_blank')}>
                        <span>¿Necesitas materiales para este servicio? <b>¡Ven a WollyShop!</b></span>
                        <img src={wrench} alt=""  />
                    </div>
                </div>
                <div className="service-details-images-wrapper">
                    {service.images ? service.images.map((image,imageIndex)=>{
                            if(imageIndex <6){
                                return (<div
                                    className="service-details-images"
                                    style={{backgroundImage:"url("+image.url()+")"}}
                                    onClick={()=>{setImageSelected(image.url()); setShowImageModal(true)}}>
                                </div>)
                            }
                        })
                         :(<div className="service-details-images-no-pics" style={{backgroundImage:service.vertical?.get("DefaultPicture")?.url()? "url("+ service.vertical?.get("DefaultPicture")?.url()+")":"url("+require("../../assets/noPics.png")+")"}}></div>)
                    }
                    <div>
                        {
                                service.jobType == "fixed" && !user.isConstructionCompany && (
                                    <>
                                        <p className="service-details-description">El servicio incluye</p>    
                                        {
                                            service.includes && (
                                                <>
                                                    {service.includes.map((include, index) => (
                                                        <p key={`include-${index}`} className="service-details-fixed-ganancy">
                                                            {`- ${include}`}
                                                        </p>
                                                    ))}
                                                </>
                                            )
                                        }
                                        <p className="service-details-description">El servicio NO incluye</p>    
                                        {
                                            service.doesntIncludes && (
                                                <>
                                                    {service.doesntIncludes.map( doesntInclude => (
                                                        <p className="service-details-fixed-ganancy">{`- ${doesntInclude}`}</p>
                                                    ))}
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                    </div>
                    
                </div>
            </div>
            <div className="service-details-wrapper-mobile">
                <div className="service-details-data-wrapper">
                    <div className="service-details-title-wrapper">
                        <img className="service-detail-vertical-img"  src={service.vertical ? service.vertical?.get("Icon")?.url() ? service.vertical?.get("Icon")?.url() : "" : service.verticalLogo._url}/>
                        <div>
                            <p className={service?.level  === 4 ? "Platino" : service?.level === 3 ? "Oro" : service?.level === 2 ? "Plata" : "Bronce"}>{service?.level  === 4 ? "Platino" : service?.level === 3 ? "Oro" : service?.level === 2 ? "Plata" : "Bronce"} - {service.type}</p>
                            <p className="service-details-title">{service?.title}</p>
                        </div>
                        {
                            service.business?.get("Logo")?.url() && (
                                <img  src={service.business?.get("Logo")?.url() ? service.business?.get("Logo")?.url(): ""}/>
                            )
                        }
                    </div>
                    <div className="service-details-info-wrapper">
                        <div className="service-details-container-wrapper">
                            <div className="service-details-row-wrapper">
                                <img src={require("../../assets/icons/User.svg")} alt="" />
                                <p className="service-details-p">{service.createdBy ? service.createdBy?.get("Name") : service.clientName}</p>
                            </div>
                            <div className="service-details-row-wrapper">
                                <img src={require("../../assets/icons/MapPinLine.svg")} alt="" />
                                <p className="service-details-p">{service.address}</p>
                            </div>
                            {!forOwn && <div className="service-details-row-wrapper">
                                <img style={{height:20}} src={require("../../assets/icons/Phone.svg")} alt="" />
                                <p className="service-details-p">   {service.createdBy ? service.createdBy?.get("Phone") : service.clientPhone}</p>
                            </div>}
                          
                            <div className="service-details-row-wrapper">
                                <img src={require("../../assets/icons/Clock.svg")} alt="" />
                                <p className="service-details-p">{service.appointment ? `Cita con el cliente: ${service.appointment.toString().substring(7,10)} ${service.appointment.toString().substring(3  ,8)} ${service.appointment.toString().substring(11,15)}` : `Subido ${moment(service?.createdAt).fromNow()}`}</p>
                            </div>
                            
                            {
                                service.fixedPrice && (
                                    <div className="service-details-row-wrapper mt-10">
                                        <p className="service-details-fixed-ganancy">Ganancia Directa:</p>
                                        <p className="service-details-fixed-price">{`${formatCurrency(service.fixedPrice)}  + IVA`}</p>
                                    </div>
                                )
                            }
                            {
                                service.jobState && service.jobState != "finalizado" && !user?.isConstructionCompany && (
                                    <div className="service-details-row-wrapper mt-10">
                                        <img style={{width:20}} src={require("../../assets/icons/Warning.svg")} alt="" />
                                        <p className="service-details-p-advice">Para recibir el pago, es obigatorio completar todos los pasos, incluidas las fotos del trabajo terminado y la firma de conformidad.</p>
                                    </div>
                                )
                            }
                            {
                                service.jobState && service.jobState == "finalizado" && (
                                    <div className="service-details-row-wrapper mt-10">
                                        <p className="service-details-p-complete">SERVICIO COMPLETADO</p>
                                        <img style={{width:40, height: 40, marginLeft: 15}} src={require("../../assets/notifications/verified.png")} alt="" />
                                    </div>
                                )
                            }
                        </div>
                        
                    </div>
                    <div className="service-details-pics-wrapper">
                        <div className="service-details-images-wrapper">
                            {service.images ? service.images.map((image,imageIndex)=>{
                                    if(imageIndex <6){
                                        return (<div className="service-details-images" style={{backgroundImage:"url("+image.url()+")"}}></div>)
                                    }
                                })
                                :(<div className="service-details-images-no-pics" style={{backgroundImage:service.vertical?.get("DefaultPicture")?.url()? "url("+ service.vertical?.get("DefaultPicture")?.url()+")":"url("+require("../../assets/noPics.png")+")"}}></div>)
                            }
                        </div>
                        <p className="service-details-description">Descripción detallada</p>
                        <p className="service-details-description-p">{service.description}</p>
                        {
                            service.jobType != "fixed" && (
                                <p className="service-details-description">Competencia</p>    
                            )
                        }
                        {
                            service.jobType != "fixed" && (
                                <div className="slotsContainer">
                                    {service?.totalSlots == 1 ? 
                                        (
                                            <>
                                                <img className="oneSlot" src={require("../../assets/n1.png")} alt="" />
                                                <p>SERVICIO SIN COPMPETENCIA</p>
                                            </>
                                        ) :(
                                            <>
                                                {calculateSlots(service?.totalSlots,service?.takenSlots,service.id)}
                                            </>
                                    )}
                                </div> 
                            )
                        }
                        {
                            service.jobType == "fixed" && !user.isConstructionCompany && (
                                <>
                                    <p className="service-details-description">El servicio incluye</p>    
                                    {
                                        service.includes && (
                                            <>
                                                {service.includes.map( include => (
                                                    <p className="service-details-fixed-ganancy">{`- ${include}`}</p>
                                                ))}
                                            </>
                                        )
                                    }
                                    <p className="service-details-description">El servicio NO incluye</p>    
                                    {
                                        service.doesntIncludes && (
                                            <>
                                                {service.doesntIncludes.map( doesntInclude => (
                                                    <p className="service-details-fixed-ganancy">{`- ${doesntInclude}`}</p>
                                                ))}
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                        <div className="service-details-wollyShop"  onClick={() => window.open('https://www.shop.wollyhome.com/', '_blank')}>
                            <span>¿Necesitas materiales para este servicio? <b>¡Ven a WollyShop!</b></span>
                        </div>
                        {!forOwn && service.jobType == "fixed" ?(<div className="service-details-buy-button-wrapper">
                                <>
                                    <button onClick={()=>fixedProcess(service)} className="service-details-buy-button">INICIAR GESTIÓN</button>
                                    <button onClick={()=>generateLink(service)} className="service-details-buy-button-clear">Compartir URL</button>
                                </>
                        </div>):
                        (<div className="service-details-buy-button-wrapper">
                            {forOwn ? (  <button onClick={()=>handleBuy(service)} className="service-details-buy-button">CONTACTAR POR {formatCurrency(service.jobLeadPrice) }</button>):
                                        service.jobType == "fixed" ? <button onClick={()=>fixedProcess(service)} className="service-details-buy-button"> INICIAR GESTIÓN </button> :
                                (  <a href={`tel:${service.createdBy?.get("Phone")}`} className="service-details-buy-button">CONTACTAR</a>)
                            }
                        </div>)}
                        
                    </div>
                </div>               
            </div>
        </MainModal>
    )
}

export default ServiceDetails;