import React, { useState, useEffect } from "react";
import { useStateValue } from "../../state/context";
import {
    getMatchFilteredWollers,
    getUsersByPromotion
} from "../../utils/ParserUtilities";
import MainModal from "./MainModal";
import Select from 'react-select';
import Lottie from "react-lottie";
import * as cardSpinnerData from "../../assets/animations/main-loading.json";
import {formatDateWhitoutHour} from "../../utils/formatter";
import { useDebounce } from "../../utils/hooks";

import "../../styles/match-jobs.css";

const MatchJobWollerModal = (props) => {

    const {onClose, isVisible, job, leadPrice} = props;
    const [{user,currentSession,agent}, dispatch] = useStateValue();

    const [wollers, setWollers] = useState([]);
    const [phone, setPhone] = useState();
    const [name, setName] = useState();
    const [distance, setDistance] = useState();
    const [selectedLevels, setSelectedLevels] = useState();
    const [selectedLeads, setSelectedLeads] = useState(leadPrice ? leadPrice : null);
    const [isOnlyPromotion, setIsOnlyPromotion] = useState(false);
    const [noGuildFilter, setNoGuildFilter] = useState(false);
    const [loader, setLoader] = useState(true);
    const [usersPromotion, setUsersPromotion] = useState([]);
    const isConstructionCompany = !user?.isGoldWoller && user?.isConstructionCompany;

    const debouncedPhone = useDebounce(phone, 2000);
    const debouncedIsOnlyPromotion = useDebounce(isOnlyPromotion, 2000);
    const debouncedName = useDebounce(name, 2000);
    const debouncedDistance = useDebounce(distance, 1000);
    const debouncedLeads = useDebounce(selectedLeads, 1000);
    const debouncedNoguildFilter = useDebounce(noGuildFilter, 1000);

    const levels =[
        {value: 1, label: "Bronce"},{value: 2,label: "Plata"}, {value: 3,label: "Oro"}, {value: 4,label: "Platino"}
    ]

    const customStyles = {
        container: provided => ({
          ...provided,
          width: 250
        })
      };
    
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cardSpinnerData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        const fetchUsersByPromotion = async () => {
            try {
                setUsersPromotion(await getUsersByPromotion(getPromotionIdByBusiness()));
            } catch (error) {
                console.error("Error fetching users by promotion", error);
            }
        };

        if (isConstructionCompany) {
            fetchUsersByPromotion();
        }
    }, [job]);

    useEffect(() => {
        const filters = {
            phone: debouncedPhone,
            name: debouncedName,
            noGuildFilter: debouncedNoguildFilter,
            isOnlyPromotion: debouncedIsOnlyPromotion,
            selectedLeads: debouncedLeads,
            distance: debouncedDistance,
            levels: selectedLevels
        };

        const fetchData = () => {
            setLoader(true);
            const queryParams = {};

            if (filters.phone && filters.phone.length > 3) {
                queryParams.newPhone = filters.phone;
            } else if (filters.name && filters.name.length > 2) {
                queryParams.newName = filters.name;
            } else if (filters.noGuildFilter !== undefined) {
                queryParams.noGuild = filters.noGuildFilter;
            } else if (filters.isOnlyPromotion !== undefined) {
                queryParams.onlyPromo = filters.isOnlyPromotion;
            } else if (filters.selectedLeads >= 0) {
                queryParams.newLeads = filters.selectedLeads;
            } else if (filters.distance !== undefined) {
                queryParams.newDistance = filters.distance;
            }
            getAllWollers(queryParams);
        };
        fetchData();

    }, [debouncedPhone, debouncedName, debouncedNoguildFilter, debouncedIsOnlyPromotion, debouncedLeads, debouncedDistance, selectedLevels]);

    const handleLevelChange = e => setSelectedLevels(e);
    const handleIsOnlyPromo = e => setIsOnlyPromotion(e.target.checked);
    const handleNoGuildFilter = e => setNoGuildFilter(e.target.checked);

    function getPromotionIdByBusiness() {
        const promotion = currentSession.contractor?.promotions.find(promotion => promotion.business === job.get("Business")?.id);
        return promotion?.id;
    }

    const getAllWollers = async ({newPhone, newName, newDistance, newLeads, noGuild}) => {
        let res = await getMatchFilteredWollers({
            phone:newPhone ? newPhone:phone,
            name:newName ? newName:name, 
            location:job.get("Location"), 
            distance:parseInt(newDistance ? newDistance:distance?distance:10), 
            selectedLevels:selectedLevels, 
            selectedLeads:newLeads ? newLeads:selectedLeads, 
            type:[job.get("Type")],
            onlyPromo:isOnlyPromotion ? getPromotionIdByBusiness() : false,
            noGuild:noGuild ? noGuild : noGuildFilter,
            contractor:isConstructionCompany ? currentSession.contractor.id:undefined});
            if(res){
                setWollers(res)

            }else{
                console.error(res)
            }
            setLoader(false);
        }

    const selectJober = async jober => {
        const jobType = job.get("JobType");
        if (jobType === "lead") {
            const joberLeads = jober.get("nJobs");
            const subscription = jober.get("UserSubscription");
            if (joberLeads >= job.get("JobLeadPrice") || subscription) {
                props.asignJober(jober);
            } else alert("Este Jober no tiene suscripción activo o suficientes créditos para aceptar este trabajo.");
        } else props.asignJober(jober);
    
    };
    return (
        <MainModal
            style={{ height: "700px", width: "1200px" }}
            title={"Selección de Wollers"}
            isVisible={isVisible}
            onClose={onClose}
        >
            {/* Buscadores */}
            <input
                type="text"
                placeholder="Busca por teléfono..."
                value={phone || ""}
                className="match-search-input"
                onChange={(e)=>setPhone(e.target.value)}
            />
            <input
                type="text"
                placeholder="Busca por nombre..."
                value={name || ""}
                className="match-search-input"
                onChange={(e)=>setName(e.target.value)}
            />

            {/* Filtros */}
            {agent.dept != "ConstructionCompany" && <div className="match-filters">
                <div className="match-filter-slider">
                    <p className="match-filter-title">Distancia máxima: {distance} km</p>
                    <input
                        className="slider"
                        type="range"
                        min={1}
                        max={90}
                        value={distance}
                        onChange={e => {
                            setDistance(e.target.value);
                            setPhone("");
                            setName("");
                        }}
                    />
                </div>
                <div className="match-filter-slider">
                    <p className="match-filter-title">Leads mínimos: {selectedLeads} €</p>
                    <input
                        className="slider"
                        type="range"
                        min={0}
                        max={30}
                        value={selectedLeads}
                        onChange={e => {
                            setSelectedLeads(e.target.value);
                            setPhone("");
                            setName("");
                        }}
                    />
                </div>
                <div className="match-filter-wrapper">
                    <p className="match-filter-select-title">Niveles:</p>
                    <Select
                        isMulti
                        styles={customStyles}
                        options={levels}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue={selectedLevels}
                        onChange={handleLevelChange} 
                    />
                </div>
            </div>}
            {/* Filtros acr */}
            {agent.dept == "ConstructionCompany" &&<div className="match-filter-wrapper-constructor">
                    <label className="labedCheckbox" htmlFor="onlyPromotion">
                        Mostrar solo a los wollers de esta promoción 
                        <input
                            id="onlyPromotion"
                            type="checkbox"
                            checked={isOnlyPromotion}
                            onChange={handleIsOnlyPromo}
                        />
                    </label>
                    <label className="labedCheckbox" htmlFor="onlyPromotion">
                        No filtrar por gremios
                        <input
                            id="onlyPromotion"
                            type="checkbox"
                            checked={noGuildFilter}
                            onChange={handleNoGuildFilter}
                            />
                    </label>

            </div>}
            {/* Filtros acr */}
                

            {
                loader && (
                    <div className="woller-match-loader">
                        <Lottie options={defaultOptions} />
                    </div>
                )
            }
            {/* Lista de Wolllers */}
            {
                !loader && (
                    
                    <div className="user-wrapper">
                        {wollers &&  wollers.length > 0 && wollers.map( woller => (
                            <div key={woller.id} className={
                                (agent.dept == "ConstructionCompany" && usersPromotion.includes(woller.id))||agent.dept != "ConstructionCompany"
                                 ? "woller-match-wrapper ": "woller-match-wrapper-noPromo"}>
                                <div>
                                    <img src={woller.get("UserPic") ? woller.get("UserPic").url() : require("../../assets/jober-icon.svg")} className="woller-match-icon"/>
                                    {woller.get("Level") > 1 && (
                                        <img
                                            className="medal-image"
                                            src={woller.get("Level") === 2
                                                    ? require("../../assets/badge-silver.svg")
                                                    : woller.get("Level") === 3
                                                    ? require("../../assets/badge-gold.svg")
                                                    : woller.get("Level") === 4
                                                    ? require("../../assets/badge-platinum.svg")
                                                    : null}
                                            alt="medal"
                                        />
                                    )}
                                </div>
                                <div className="woller-match-details">
                                    <p className="woller-match-name">{woller.get("Name")}</p>
                                    <p>{woller.get("Phone")}</p>
                                </div>
                                <div className="woller-match-details">
                                    <p>F.Creación: {formatDateWhitoutHour(woller.get("createdAt")?.toString())}</p>
                                    <p>U.conexion: {formatDateWhitoutHour(woller.get("Updated") ? woller.get("Updated")?.toString() : "Ningún registro")}</p>
                                </div>
                                {agent.dept != "ConstructionCompany"  && <div className="woller-match-details">
                                    <p>Saldo: {woller.get("nJobs")}</p>
                                    {woller.get("Business") && (
                                        <p>B: {woller.get("Business").get("Name")}</p>
                                    )}
                                </div>}
                                <button className={(job.get("JobType") == "lead" && woller.get("nJobs") < job.get("JobLeadPrice")) ? "woller-match-button-red" : "woller-match-button-blue"} onClick={() => selectJober(woller)}>
                                    {job.get("JobType") == "lead" ? `Precio ${job.get("JobLeadPrice")}€` : "Seleccionar"}
                                </button>
                            </div>
                        ))}
                    </div>
                )
            }
        </MainModal>
    )
}

export default MatchJobWollerModal;