import React from "react";

import ExtraInfoCell from "./detailColumn/ExtraInfoCell";
import FileInfoCell from "./detailColumn/FileInfoCell";
import ButtonEmpty from "../../button/ButtonEmpty";
import ImageModal from "../../modal/ImageModal"

import {Input} from 'jobin-ui-components'
import { useState } from "react";

export default function InfoCell(props) {
    const [showImageModal, setShowImageModal] = useState(false)
    const [imageSelected, setImageSelected] = useState(null)
    const style = {
        cellContainer: {
            display: "flex",
            flexDirection: "column",
            marginTop: 8,
            paddingTop: 8,
            paddingBottom: 8,
            borderBottom: "1px solid var(--soft-grey)",
            overflow: "hidden",
            ...props.style
        },
        cellContainerHalfRight: {
            display: "flex",
            flexDirection: "column",
            marginTop: 8,
            paddingTop: 8,
            paddingBottom: 8,
            borderBottom: "1px solid var(--soft-grey)",
            width: "47%",
            marginLeft: "3%",
            ...props.style
        },
        cellContainerHalfLeft: {
            display: "flex",
            flexDirection: "column",
            marginTop: 8,
            paddingTop: 8,
            paddingBottom: 8,
            borderBottom: "1px solid var(--soft-grey)",
            width: "47%",
            marginRight: "3%",
            ...props.style
        },
        titleView: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center"
        },
        title: {
            fontSize: 12,
            color: "var(--greyish)"
        },
        imageCell: {
            height: 56,
            width: 56,
            marginRight: 8,
            marginTop: 8,
            borderRadius: 4,
            overflow: "hidden",
            backgroundColor: "var(--soft-grey)"
        },
        contentView: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center"
        },
        logoView: {
            height: 32,
            width: 32,
            marginRight: 8,
            borderRadius: 16,
            overflow: "hidden",
            objectFit: "cover"
        }
    };

    return (
        <section
            style={
                props.halfLeft
                    ? style.cellContainerHalfLeft
                    : props.halfRight
                    ? style.cellContainerHalfRight
                    : style.cellContainer
            }
        >
            {showImageModal && <ImageModal isVisible={showImageModal}  onClose={()=>setShowImageModal(false)} image={imageSelected}/>}
            <div style={style.titleView}>
                <div>
                    <label style={style.title}>{props.title}</label>
                </div>
                {props.optionAction && (
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-end"
                        }}
                    >
                        <ButtonEmpty
                            buttonText={props.optionText}
                            onClick={props.optionAction}
                        />
                    </div>
                )}
            </div>
            <div style={props.dataArray ? null : style.contentView}>
                {props.logo && <img style={style.logoView} src={props.logo} alt='Icono' />}
                {props.editable ? (
                    <Input
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1
                        }}
                        badInput={props.badInput}
                        placeholder={props.inputPlaceholder}
                        onChange={props.onInputChange}
                        defaultValue={props.inputValue}
                    />
                ) : props.data ? (
                    <p>{props.data}</p>
                ) : props.buttonText ? (
                    <ButtonEmpty
                        style={{ textAlign: "left" }}
                        buttonText={props.buttonText}
                        onClick={props.onClick}
                    />
                ) : props.imagesData ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {props.imagesData.map((item, index) => {
                            return (
                                <div
                                    key={"image" + index}
                                    style={style.imageCell}
                                    onClick={()=>{setImageSelected(item.url());setShowImageModal(true)}}
                                >
                                    <img src={item.url()} alt={"item prop"+index} style={{"height" : "100%"}}></img>
                                </div>
                            );
                        })}
                    </div>
                ) : (props.dataArray && props.dataArray.length > 0) ? (
                    props.dataArray.map((item, index) => {
                        if (item.pricePerUnit) {
                            //Is a extra
                            return (
                                <ExtraInfoCell
                                    key={"extra" + index}
                                    extra={item}
                                />
                            );
                        } else if (item.filename) {
                            //Is a file cell
                            return (
                                <FileInfoCell
                                    key={"file" + index}
                                    extra={item}
                                    onClick={props.optionAction}
                                />
                            );
                        } else {
                            //Is a include/ noninclude
                            return (
                                <p
                                    style={{ marginTop: 4, marginBottom: 4 }}
                                    key={"label" + index}
                                >
                                    {"- " + item}
                                </p>
                            );
                        }
                    })
                ) : (
                    <p>No hay datos</p>
                )}
            </div>
        </section>
    );
}
