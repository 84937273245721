import React, { useState, useEffect } from "react";
import moment from "moment";
//components
import SearchBar  from "./SearchBar";
import FilterDate from "../filter/FilterDate";
import FilterMultipleSelection from "../filter/FilterMultipleSelection";
import ButtonImage from "../button/ButtonImage";
import BoosterModal from "../modal/BoosterModal";
import DispatModal from "../modal/DispatModal";
import AlertModal from "../modal/Alert";
import ImportWollersModalp from "../modal/ImpotServicesModal"
import NewServiceModal from "../modal/NewServiceModal"

//api calls
import { holdStandBy,searchJobsByName,findJobByIDs,searchJobsByUserData, getJobAXAMediator } from "../../utils/ParserUtilities";
//utils and const
import { formatCurrency } from "../../utils/formatter";
import { provinceArr } from "../../constants/provinces";
import { levelArr } from "../../constants/levels";
import { slotArr } from "../../constants/slots";
import { jobStateArr, fixedStateArr } from "../../constants/jobState";
import { budgetState } from "../../constants/budgetState";
import { getFilterAndActions } from "../../constants/crm/filtersAcces";
import { useDebounce } from "../../utils/hooks";
//context
import { useStateValue } from "../../state/context";
//styles
import "../../styles/search/search-nav.css";
//assets
import menuIcon from "../../assets/search/menu.svg"
import refreshIcon from "../../assets/refreshGrey.svg"
import alertIconActive from "../../assets/icons/alertIconActive.svg"
import alertIcon from "../../assets/icons/alertIcon.svg"
import settingsIcon from "../../assets/settingsIcon.png"
import rayo from "../../assets/rayo.svg"
import tableChart from "../../assets/table-chart.svg"
import add from "../../assets/add.svg"
import Button from "../button/Button";

export default function SearchNav(props) {
    const [{ user,search,general,currentSession,agent},dispatch] = useStateValue();
    const [showBoosterModal, setShowBoosterModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [showNewServiceModal, setShowNewServiceModal] = useState(false);
    const [dispatModalShow, setDispatModalShow] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [confirmHoldDialog, setConfirmHoldDialog] = useState(false);
    const [clearFilters_, setClearFilters_] = useState()
    
    const [date_, setDate_] = useState()
    const [appointmentdate_, setAppointmentDate] = useState()
    const [provinces_, setProvinces] = useState()
    const [guilds_, setGuilds] = useState()
    const [levels_, setLevels] = useState()
    const [slots_, setSlots] = useState()
    const [jobStates_, setJobStates] = useState()
    const [agents_, setAgents] = useState()
    const [businesses_, setBusinesses] = useState()
    const [budget_, setBudget] = useState()
    const [onlyIncidences_, setOnlyIncidences] = useState()
    const [idType_, setIdType] = useState()
    
    const debounceClearFilters = useDebounce(clearFilters_, 500);
    const debounceDate = useDebounce(date_, 500);
    const debounceAppointment = useDebounce(appointmentdate_, 500);
    const debounceProvinces = useDebounce(provinces_, 1000);
    const debounceGuilds = useDebounce(guilds_, 1000);
    const debounceLevels = useDebounce(levels_, 1000);
    const debounceSlots = useDebounce(slots_, 1000);
    const debounceJobStates = useDebounce(jobStates_, 1000);
    const debounceAgents = useDebounce(agents_, 1000);
    const debounceBusinesses = useDebounce(businesses_, 1000);
    const debounceBudget = useDebounce(budget_, 1000);
    const debounceOnlyIncidences = useDebounce(onlyIncidences_, 1000);
    const debounceIdType = useDebounce(idType_, 1000);
   
    useEffect(()=>{
        if(search.services.length <= 0 && !user.isMediator){

            props.findJobsByFilters({});
            dispatch({
                type: "SET_SEARCH_FIND_JOBS",
                findJobs:props.findJobsByFilters,
            });
            
        }else if(user.isMediator){
            dispatch({
                type: "SEARCH_RESULTS",
                services:[],
                count:0
            });
        }
        handleAccesControl()
    },[])
    
    useEffect(()=>{
        if(debounceClearFilters){
            props.findJobsByFilters({});
        }
    },[debounceClearFilters])
    useEffect(()=>{
        if(debounceDate) {
            dispatch({
            type: "SEARCH_FILTER_DATE",
                startDate: debounceDate.startDate ? new Date(debounceDate.startDate) : null,
                endDate: debounceDate.endDate ? new Date(debounceDate.endDate) : null,
            });
            dispatch({
                type:"SEARCH_CHANGE_PAGE",
                currentPage:1
            })
            if(debounceDate.startDate !== null) {
                props.findJobsByFilters({
                    startDate: debounceDate.startDate ? debounceDate.startDate : null,
                    endDate: debounceDate.endDate ? debounceDate.endDate : null,
                    page: 1
                });
            }
        }
    },[debounceDate])
    useEffect(()=>{
        if(debounceAppointment) {
            dispatch({
            type: "SEARCH_FILTER_APPOINTMENT",
                appointmentStart: debounceAppointment.startDate ? new Date(debounceAppointment.startDate) : null,
                appointmentEnd: debounceAppointment.endDate ? new Date(debounceAppointment.endDate) : null,
            });
            dispatch({
                type:"SEARCH_CHANGE_PAGE",
                currentPage:1
            })
            if(debounceAppointment.startDate !== null) {
                props.findJobsByFilters({
                    appointmentStart: debounceAppointment.startDate ? debounceAppointment.startDate : null,
                    appointmentEnd: debounceAppointment.endDate ? debounceAppointment.endDate : null,
                    page: 1
                });
            }
        }
    },[debounceAppointment])
    useEffect(()=>{
        if(debounceOnlyIncidences!== undefined){
            dispatch({
                type: "SEARCH_FILTER_INCIDENCES",
                incidences:debounceOnlyIncidences,
            });
            dispatch({
                type:"SEARCH_CHANGE_PAGE",
                currentPage:1
            })
            props.findJobsByFilters({onlyIncidences:debounceOnlyIncidences,page:1});
        }
    },[debounceOnlyIncidences])
    useEffect(()=>{
        if(debounceProvinces !== undefined){
            dispatch({
               type: "SEARCH_FILTER_PROVINCES",
               provinces:debounceProvinces,
           });
           dispatch({
               type:"SEARCH_CHANGE_PAGE",
               currentPage:1
            })
            props.findJobsByFilters({provinces:debounceProvinces,page:1});
        }
    },[debounceProvinces])
    useEffect(()=>{
        if(debounceGuilds !== undefined){
            dispatch({
                type: "SEARCH_FILTER_GUILDS",
                guilds:debounceGuilds,
            });
            dispatch({
                type:"SEARCH_CHANGE_PAGE",
                currentPage:1
            })
            props.findJobsByFilters({guilds:debounceGuilds,page:1});
        }
    },[debounceGuilds])
    useEffect(()=>{
        if(debounceLevels !== undefined){
            dispatch({
                type: "SEARCH_FILTER_LEVELS",
                levels:debounceLevels,
            });
            dispatch({
                type:"SEARCH_CHANGE_PAGE",
                currentPage:1
            })
            props.findJobsByFilters({levels:debounceLevels,page:1});
        }
    },[debounceLevels])
    useEffect(()=>{
        if(debounceSlots !== undefined){
            dispatch({
                type: "SEARCH_FILTER_SLOTS",
                slots:debounceSlots,
            });
            dispatch({
                type:"SEARCH_CHANGE_PAGE",
                currentPage:1
            })
            props.findJobsByFilters({slots:debounceSlots,page:1});
        }
    },[debounceSlots])
    useEffect(()=>{
        if(debounceJobStates !== undefined){
            dispatch({
                type: "SEARCH_FILTER_JOBSTATE",
                jobStates:debounceJobStates,
            });
            dispatch({
                type:"SEARCH_CHANGE_PAGE",
                currentPage:1
            })
            props.findJobsByFilters({jobStates:debounceJobStates,page:1});
        }
    },[debounceJobStates])
    useEffect(()=>{
        if(debounceBusinesses !== undefined){
            dispatch({
                type: "SEARCH_FILTER_BUSINESSES",
                businesses:debounceBusinesses,
            });
            dispatch({
                type:"SEARCH_CHANGE_PAGE",
                currentPage:1
            })
            props.findJobsByFilters({businesses:debounceBusinesses,page:1});
        }
    },[ debounceBusinesses])
    useEffect(()=>{
        if(debounceAgents !== undefined){
            dispatch({
                type: "SEARCH_FILTER_AGENTS",
                agents:debounceAgents,
            });
            dispatch({
                type:"SEARCH_CHANGE_PAGE",
                currentPage:1
            })
            props.findJobsByFilters({agents:debounceAgents,page:1});
        }
    },[debounceAgents])
    useEffect(()=>{
        if(debounceBudget !== undefined){
            dispatch({
               type: "SEARCH_FILTER_BUDGET",
               budget:debounceBudget,
           });
           dispatch({
               type:"SEARCH_CHANGE_PAGE",
               currentPage:1
            })
            props.findJobsByFilters({budget:debounceBudget,page:1});
        }
    },[debounceBudget])
    useEffect(()=>{
        if(debounceIdType !== undefined){
            dispatch({
                type: "SEARCH_FILTER_ID",
                currentIdType:debounceIdType,
            });
            let selectedType = search.idTypes.map(idType=>{
                if(debounceIdType == idType.value){
                    idType.selected =true
                }else{
                    idType.selected =false
                }
                return idType
            })

           dispatch({
               type:"SET_ID_OPTIONS",
               idTypes:selectedType
            })
           dispatch({
               type:"SEARCH_CHANGE_PAGE",
               currentPage:1
            })
            if(user.business){
                props.findJobsByFilters({type:debounceIdType,page:1, businesses: [user.business.id]});
            } else props.findJobsByFilters({type:debounceIdType,page:1});
        }
    },[debounceIdType])
    


    //acces control
    const handleAccesControl = async ()=>{
        let allowedFiltes_ = await getFilterAndActions(user.isGoldWoller ? "goldUser": user.isB2b? "B2B":user.isMediator?"mediator":"agent",agent.dept,agent.id,"filters")
        let allowedactions_ = await getFilterAndActions(user.isGoldWoller ? "goldUser": user.isB2b? "B2B":user.isMediator?"mediator":"agent",agent.dept,agent.id,"actions")
        let allowedData_ = await getFilterAndActions(user.isGoldWoller ? "goldUser": user.isB2b? "B2B":user.isMediator?"mediator":"agent",agent.dept,agent.id,"data")
    
        dispatch({
            type: "SET_ALLOWED_DATA",
            allowedFilters:{
                extendedSearch : allowedFiltes_.filter(element=>element === "extendedSearch").length > 0,
                date: allowedFiltes_.filter(element=>element === "date").length > 0,
                province: allowedFiltes_.filter(element=>element === "province").length > 0,
                service: allowedFiltes_.filter(element=>element === "service").length > 0,
                level: allowedFiltes_.filter(element=>element === "level").length > 0,
                slot: allowedFiltes_.filter(element=>element === "slot").length > 0,
                state: allowedFiltes_.filter(element=>element === "state").length > 0,
                budget: allowedFiltes_.filter(element=>element === "budget").length > 0,
                incidences: allowedFiltes_.filter(element=>element === "incidences").length > 0,
                businesses: allowedFiltes_.filter(element=>element === "businesses").length > 0,
                agents: allowedFiltes_.filter(element=>element === "agents").length > 0,
                appointment: allowedFiltes_.filter(element=>element === "appointment").length > 0,
                clear: allowedFiltes_.filter(element=>element === "clear").length > 0,
                jobType: allowedFiltes_.filter(element=>element === "jobType").length > 0,
                mediator: allowedFiltes_.filter(element=>element === "mediator").length > 0,
            },
            allowedActions:{
                new: allowedactions_.filter(element=>element === "new").length > 0,
                uploadExcel: allowedactions_.filter(element=>element === "uploadExcel").length > 0,
                pipedrive: allowedactions_.filter(element=>element === "pipedrive").length > 0,
                standBy: allowedactions_.filter(element=>element === "standBy").length > 0,
                dispat: allowedactions_.filter(element=>element === "dispat").length > 0,
                booster: allowedactions_.filter(element=>element === "booster").length > 0,
                manageJob: allowedactions_.filter(element=>element === "manageJob").length > 0,
            },
            allowedData:{
                balance: allowedData_.filter(element=>element === "balance").length > 0
            },
        });
    }

    //actions
    const handleHoldStandBy= ()=>{
        dispatch({
            type: "SET_HOLD_STANDBY",
            holdStandBy:!general.holdStandBy,
        });
        holdStandBy()
    }
    const createNewJob= ()=>{
        props.history.push({
            pathname: "/job/new",
            state: {
                inner: true
            }
        });
    }
    
    //filters acctions
    const fetchJobsByDate = async date => {
        setDate_({startDate: date.clearDates ? undefined : date.startDate, endDate: date.clearDates ? undefined : date.endDate})
    }

    const fetchJobsByAppointment = async date => {
        setAppointmentDate({startDate: date.clearDates ? undefined : date.startDate, endDate: date.clearDates ? undefined : date.endDate})
    }

    const fetchJobsByIncidences = () => {
        if(onlyIncidences_ === undefined){
            setOnlyIncidences(true)
        }else{
            setOnlyIncidences(!onlyIncidences_)
        }
    }

    const fetchJobsByProvince = async selection => {
        selection = selection.map(selected=>{
            if(selected.id !== "none"){
                return selected.id
            }
        }).filter(x=>x)
       
       setProvinces(selection)
    }

    const fetchJobsByGuild = async selection => {
        selection = selection.map(selected=>{
            if(selected.id !== "none"){
                return selected.id
            }
        }).filter(x=>x)
       if(user.isGoldWoller && currentSession.guilds && selection.length === 0){
            let guildsId = currentSession.guilds.map(guild_ =>guild_.id)
           setGuilds(guildsId)
        }else{
            setGuilds(selection)
       }
    }

    const fetchJobsByLevel = async selection => {
        selection = selection.map(selected=>{
            if(selected.id !== "none"){
                return parseInt(selected.id)
            }
        }).filter(x=>x)
       
        setLevels(selection)
    }

    const fetchJobsBySlot = async selection => {
        selection = selection.map(selected=>{
            if(selected.id !== "none"){
                return selected.id
            }
        }).filter(x=>x)
       
        setSlots(selection)
    }

    const fetchJobsByJobState = async selection => {
        selection = selection.map(selected=>{
            if(selected.id !== "none"){
                return selected.id
            }
        }).filter(x=>x)
       
        setJobStates(selection)
    }

    const fetchJobsByBusiness = async selection => {
        
        selection = selection.map(selected=>{
            if(selected.id !== "none"){
                return selected.id
            }
        }).filter(x=>x)
        if(selection.length === 0 && user.isConstructionCompany){
            setBusinesses(general.businesses
            .filter(business => business.id !== 'all')
            .map(business => business.id));
        } else {
            setBusinesses(selection);
        }
    }
    
    const fetchJobsByAgent = async selection => {
        selection = selection.map(selected=>{
            if(selected.id !== "none"){
                return selected.id
            }
        }).filter(x=>x)
        setAgents(selection)
    }

    const fetchJobsByBudget = async selection => {
        selection = selection.map(selected=>{
            if(selected.id !== "none"){
                return selected.id
            }
        }).filter(x=>x)
        
       setBudget(selection)
    }
    const handleChangeIdType = async selection => {
        
        dispatch({
            type: "SEARCH_RESULTS",
            services:[],
            count:0
        });
        dispatch({
            type: "SEARCH_CLEAN",
            businesses:user.isB2b ? search.businesses : undefined 
        });
        setClearFilters_(true)
        setDate_(undefined)
        setAppointmentDate(undefined)
        setProvinces(undefined)
        setGuilds(undefined)
        setLevels(undefined)
        setSlots(undefined)
        setJobStates(undefined)
        setAgents(undefined)
        if(!user.isB2b){
            setBusinesses(undefined)
        }
        setBudget(undefined)
        setOnlyIncidences(undefined)
       setIdType(selection)
    }

    const fetchJobsById = async id => {
        dispatch({
            type: "SEARCH_LOADING",
            loading: true,
        });
        let jobsPromise = findJobByIDs(id,search.currentIdType)
        Promise.all([jobsPromise]).then(([results]) => {
            if(results.status =="ok"){
                dispatch({
                    type: "SEARCH_RESULTS",
                    services:[results.result],
                    count:1
                });
            }else{
                dispatch({
                    type: "SEARCH_RESULTS",
                    services:[],
                    count:0
                });
            }
            dispatch({
                type: "SEARCH_LOADING",
                loading: false,
            });
            
            
        })
        .catch((error) => {
            dispatch({
                type: "SEARCH_LOADING",
                loading: false,
            });
            
        })
    }
    const fetchMediatorJobs = async title => {
        dispatch({
            type: "SEARCH_LOADING",
            loading: true,
        });
        let jobsPromise = getJobAXAMediator(title)
        Promise.all([jobsPromise]).then(([results]) => {
            dispatch({
                type: "SEARCH_RESULTS",
                services:results,
                count:results.length
            });
            dispatch({
                type: "SEARCH_LOADING",
                loading: false,
            });
            
        })
        .catch((error) => {
            dispatch({
                type: "SEARCH_LOADING",
                loading: false,
            });
        })
    }

    const fetchJobsByJobName = async (value)=> {
        dispatch({
            type: "SEARCH_LOADING",
            loading: true
        });
        const result = await searchJobsByName(value, user.isB2b ? user.business.id:user.isGoldWoller?currentSession?.wollerData?.id:null, user.isB2b ? "b2b":user.isGoldWoller?"goldUser":null,search.currentIdType);
        Promise.all([result]).then(([results,count]) => {
            dispatch({
                type: "SEARCH_RESULTS",
                services:results.services.services,
                count:results.services.count
            });
            dispatch({
                type: "SEARCH_LOADING",
                loading: false,
            });
        })
        
    }

    const fetchJobsByUserName = async (value)=> {
        dispatch({
            type: "SEARCH_LOADING",
            loading: true
        });
        const result = await searchJobsByUserData(value,null,user.isB2b ? user.business.id:null,search.currentIdType);
         Promise.all([result]).then(([results]) => {
            dispatch({
                type: "SEARCH_RESULTS",
                services:results.services,
                count:results.services.length
            });
            dispatch({
                type: "SEARCH_LOADING",
                loading: false,
            });
            
        })
    }

    const fetchJobsByUserPhone = async (value)=> {
        dispatch({
            type: "SEARCH_LOADING",
            loading: true
        });
        const result = await searchJobsByUserData(null,value,user.isB2b ? user.business.id:null,search.currentIdType);
        Promise.all([result]).then(([results]) => {
            dispatch({
                type: "SEARCH_RESULTS",
                services:results.services,
                count:results.services.length
            });
            dispatch({
                type: "SEARCH_LOADING",
                loading: false,
            });
            
        })
    }

    //clear filters
    const clearFilters = async ()=> {
        dispatch({
            type: "SEARCH_CLEAN",
            specialGuilds:user.isGoldWoller ?currentSession.guilds:[],
            specialJobStates:user.isGoldWoller ?["abierto", "full"]:[],
        });
        dispatch({
            type:"SEARCH_CHANGE_PAGE",
            currentPage:  1
        })
  
        setClearFilters_(true)
        setDate_(undefined)
        setAppointmentDate(undefined)
        setProvinces(undefined)
        setGuilds(undefined)
        setJobStates(undefined)
        setLevels(undefined)
        setSlots(undefined)
        setAgents(undefined)
        setBusinesses(undefined)
        setBudget(undefined)
        setOnlyIncidences(undefined)
        props.findJobsByFilters({
            startDate:null,
            endDate:null,
            appointmentStart:null,
            appointmentEnd:null,
            provinces:[],
            guilds:user.isGoldWoller ?currentSession.guilds:[],
            levels:[],
            slots:[],
            jobStates:user.isGoldWoller ?["abierto", "full"]:[],
            agents:[],
            businesses:[],
            budget:[],
            onlyIncidences:false,
            page:1,
            type:search.currentIdType,
        })
    }   

    //ux
    const toggleMenu = () => {
        setCollapsed(!collapsed);
        dispatch({
            type: "SEARCH_NAVCOLLAPSED",
            navCollapsed: !collapsed
        });
    }

    const doRefresh = () => {
        props.findJobsByFilters({})
    }

    const changePipedrive = () => {
        dispatch({
            type: "SEARCH_PIPEDRIVE",
            pipedrive: !search.pipedrive
        });
    }

    return (
        <section className="search-nav">
            {showBoosterModal && (
                <BoosterModal
                    isVisible={showBoosterModal}
                    onClose={() => setShowBoosterModal(false)}
                />
            )}
            {dispatModalShow && (
                <DispatModal
                    isVisible={dispatModalShow}
                    onClose={() => setDispatModalShow(false)}
                />
            )}
            {confirmHoldDialog && (
                <AlertModal
                    title={general.holdStandBy? "¿Estas seguro que desas liberar los trabajos en \"StandBy\"?":"¿Estas seguro que deseas mantener en \"StandBy\"?"}
                    subtitle={general.holdStandBy? "Con esta accion liberaras todos los trabajos de tipo \"lo que sea\" que estan en estado \"StandBy\"":"Con esta accion mantendras los trabajos de tipo \"Lo que sea\" en \"StandBy\" hasta que la opcion sea desactivada"}
                    isVisible={confirmHoldDialog}
                    big={true}
                    onClose={() => {setConfirmHoldDialog(false);}}
                    onClick={()=>{handleHoldStandBy();setConfirmHoldDialog(false);}}
                    buttonText={general.holdStandBy?"Desactivar":"Activar"}
                />
            )}
            {showImportModal && (
                <ImportWollersModalp
                    isVisible={showImportModal}
                    onClose={() => setShowImportModal(false)}
                />
            )}
            {showNewServiceModal && (
                <NewServiceModal
                    isVisible={showNewServiceModal}
                    refresh={()=>props.findJobsByFilters({})}
                    promotion={currentSession?.contractor?.promotions}
                    onClose={() => setShowNewServiceModal(false)}
                />
            )}
            <div className="above">
                <div className="container-fluid">
                    <div className="col-md-5">
                        <div className="row">
                            <SearchBar  
                                type={ user.isGoldWoller || user.isConstructionCompany ?"searchBar":"searchBarId"}
                                searchTerm={search.prevIDSearch}
                                placeholder={"Introduce el ID del servicio"} 
                                types={search.idTypes} 
                                searchMediatorFunction={fetchMediatorJobs} 
                                handleChangeIdType={handleChangeIdType} 
                                searchFunction={fetchJobsById}
                            />
                            {currentSession.allowedFilters?.extendedSearch && <ButtonImage
                                src={menuIcon}
                                onClick={toggleMenu}
                                className={collapsed? "toggleButtonActive dropdown-toggle":"toggleButton dropdown-toggle"}
                                disableHover={true}
                            />}

                            <Button
                                buttonText={"Actualizar"}
                                src={refreshIcon}
                                className={"refreshButton"}
                                onClick={doRefresh}
                            >
                            </Button>
                        </div>
                    </div>
                    <div className="col-md-7 text-right">
                        {currentSession.allowedActions?.uploadExcel &&  <ButtonImage
                            buttonText="Importar servicios"
                            style={{ display: "inline-block" }}
                            src={require("../../assets/import.svg")}
                            onClick={() => setShowImportModal(true)}
                        />}
                        {currentSession.allowedActions?.standBy && <ButtonImage
                            src={general.holdStandBy? alertIconActive:alertIcon}
                            buttonText={general.holdStandBy? "StandBy On":'StandBy Off'}
                            onClick={() => setConfirmHoldDialog(true)}
                            style={{ display: "inline-block" }}
                            className={general.holdStandBy? "buttonHold":"buttonNoHold"}
                            disableHover={true}
                        />}
                        {currentSession.allowedActions?.dispat && <ButtonImage
                            src={settingsIcon}
                            buttonText={'Dispat'}
                            onClick={() => setDispatModalShow(true)}
                            style={{ display: "inline-block" }}
                        />}
                    
                        {currentSession.allowedActions?.booster && <ButtonImage
                            src={rayo}
                            buttonText={'Booster Broker'}
                            onClick={() => setShowBoosterModal(true)}
                            style={{ display: "inline-block" }}
                        />}
                        
                        {currentSession.allowedActions?.pipedrive && <ButtonImage
                            src={tableChart}
                            buttonText={search.pipedrive ? "Tablas" : "Pizarra"}
                            onClick={changePipedrive}
                            style={{ display: "inline-block" }}
                        />}
                                
                        {agent.dept != "ConstructionCompany" && currentSession.allowedActions?.new && <ButtonImage
                            src={add}
                            buttonText="Nuevo"
                            onClick={createNewJob}
                            style={{ display: "inline-block" }}

                        />}
                        {agent.dept == "ConstructionCompany" && <ButtonImage
                            src={add}
                            buttonText="Nuevo"
                            onClick={()=>setShowNewServiceModal(true)}
                            style={{ display: "inline-block" }}

                        />}
                    </div>
                </div>
            </div>
            { collapsed && (
                <div className="above">
                    <div className="container-fluid">
                        <div className="col-md-3" style={{ marginRight: 24 }}>
                            <div className="row">
                                <SearchBar
                                    type={"searchBar"}  
                                    searchFunction={fetchJobsByUserName}
                                    placeholder={"Buscar por nombre del cliente"}
                                    searchTerm={search.prevClientNameSearch}
                                    isClear={clearFilters_}
                                />
                            </div>
                        </div>
                        <div className="col-md-3"  style={{ marginRight: 24 }}>
                            <div className="row">
                                <SearchBar
                                    type={"searchBar"}  
                                    searchFunction={fetchJobsByUserPhone}
                                    placeholder={"Buscar por teléfono del cliente"}
                                    searchTerm={search.prevClientPhoneSearch}
                                    isClear={clearFilters_}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="row">
                                <SearchBar
                                    type={"searchBar"}  
                                    placeholder={"Buscar por nombre del servicio"}
                                    searchFunction={fetchJobsByJobName}
                                    searchTerm={search.prevServiceNameSearch}
                                    isClear={clearFilters_}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="below">
                <div className="below-left">
                    {currentSession.allowedFilters?.date && <FilterDate
                        style={{ marginRight: 4 }}
                        type="date"
                        title="Fecha de creación"
                        startDate={date_ && date_.startDate ? moment(date_.startDate).utc() : null}
                        endDate={date_ && date_.endDate ? moment(date_.endDate).utc() : null}
                        selectionChange={fetchJobsByDate}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />}
                    {search.currentIdType === "fixed" && currentSession.allowedFilters?.appointment && <FilterDate
                        style={{ marginRight: 4 }}
                        type="date"
                        title="Fecha de cita"
                        startDate={search.appointmentStart ? moment(search.appointmentStart).utc() : null}
                        endDate={search.appointmentEnd ? moment(search.appointmentEnd).utc() : null}
                        selectionChange={fetchJobsByAppointment}
                        appointmentFilter={true}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />}
                    
                    {currentSession.allowedFilters?.province && <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="province"
                        title="Provincias"
                        items={provinceArr}
                        selectionChange={fetchJobsByProvince}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />}
                    {currentSession.allowedFilters?.service && <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="service"
                        title="Gremios"
                        items={general.guilds}
                        selectionChange={fetchJobsByGuild}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />}
                    {currentSession.allowedFilters?.level && <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="level"
                        title="Niveles"
                        items={levelArr}
                        selectionChange={fetchJobsByLevel}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />}
                    { search.currentIdType === "lead" && currentSession.allowedFilters?.slot && <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="slot"
                        title="Puestos"
                        prefix={true}
                        items={slotArr}
                        selectionChange={fetchJobsBySlot}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />}
                    {currentSession.allowedFilters?.state && <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="custom"
                        title="Estado del trabajo"
                        items={
                            search.currentIdType === "fixed"
                                ? fixedStateArr
                                : jobStateArr
                        }
                        selectionChange={fetchJobsByJobState}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />}
                    { search.currentIdType === "lead" && currentSession.allowedFilters?.budget && <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="level"
                        title="Estado del presupuesto"
                        items={budgetState}
                        selectionChange={fetchJobsByBudget}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />}
                    {currentSession.allowedFilters?.incidences && <button
                        className={!onlyIncidences_ ? 'onlyIncidencesTrue' : 'onlyIncidencesFalse'}
                        onClick={(e)=>{fetchJobsByIncidences()}}>
                            Incidencias
                    </button>}
                
                    {currentSession.allowedFilters?.businesses && <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="businesses"
                        title={currentSession.contractor ? "Promociones" : "Negocios"}
                        items={general.businesses}
                        selectionChange={fetchJobsByBusiness}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />}
                   
                    {currentSession.allowedFilters?.agents && <FilterMultipleSelection
                        style={{ marginRight: 4 }}
                        type="agent"
                        title="Agente asignado"
                        items={general.agents}
                        selectionChange={fetchJobsByAgent}
                        isClearFilters={clearFilters_}
                        settingClearFilters={setClearFilters_}
                    />}
                    {currentSession.allowedFilters?.clear && 
                        <button 
                            className="onlyIncidencesTrue"
                            onClick={()=>clearFilters()}>Limpiar Filtros</button>}
                </div>
                {currentSession.allowedData?.balance && <span className="below-right" ><b>Saldo disponible: {formatCurrency(currentSession.wollerData?.get("nJobs"))}</b></span>}
            </div>  
        </section>
    );
}
