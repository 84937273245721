import React, { useState, useEffect, CSSProperties } from "react";
import Parse from 'parse'
import '../../../styles/badges.css';
import {apiId, jsKey, urlServer} from "../../../constants/credentials";
import ClipLoader from "react-spinners/ClipLoader";
import {formatDateWhitoutHour, formatRelativeDate} from "../../../utils/formatter";
import {ButtonRow} from "../../billing/receipt/BillRows";
import refreshIcon from "../../../assets/refreshGrey.svg";
import Button from "../../button/Button";
import Alert from "../../modal/Alert";
import {useStateValue} from "../../../state/context";

export default function SubscriptionCell(props) {
    const style = {
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            border: "1px solid var(--soft-grey)",
            borderRadius: 4,
            padding: 12,
            marginTop: 16,
            marginBottom: 16,
            marginRight: 8,
            marginLeft: 8,
            backgroundColor: "#fff",
            boxShadow: "5px 5px 5px 0px var(--soft-grey)"
        },
        titleContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            fontWeight: "bold",
            marginBottom: 6,
            textAlign: "center"
        },
        title: {
            flex: 1,
            fontSize: 15,
        },
        userContainer: {
            display: "flex",
            flex: 1,
            marginTop: 12,
            alignItems: "center"
        },
        userLabel: {
            fontSize: 13
        },
        alignCenter: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        textBold: {
            fontWeight: "bold"
        },
        portalButton: {
            fontWeight: "bold",
            color: "rgb(255, 226, 53)",
            width: "100%",
            marginBottom: 10
        },
        renewButton: {
            fontWeight: "bold",
            backgroundColor: "#6BC167",
            width: "100%",
            marginBottom: 10
        },
        cancelButton: {
            fontWeight: "bold",
            backgroundColor: "#ff4436",
            width: "100%",
            marginBottom: 10
        },
        sectionTitle: {
            marginTop: 12,
            marginBottom: 15,
            fontSize: 15
        },
        selectPlan: {
            width: '100%',
            padding: '5px',
            border: '1px solid #E3E3E3',
            borderRadius: '4px',
            marginBottom: 10
        }

    };

    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(true);
    const [checkActive, setCheckActive] = useState(true);
    const [checkActiveStripe, setCheckActiveStripe] = useState(true);
    const [wollyPlanName, setWollyPlanName] = useState(false);
    const [wollyPlanId, setWollyPlanId] = useState(false);
    const [planNameStripe, setPlanNameStripe] = useState(false);
    const [endDate, setEndDate] = useState(false);
    const [endDateStripe, setEndDateStripe] = useState(false);
    const [portalLinkStripe, setPortalLinkStripe] = useState(false);
    const [limitAlgorithmResult, setLimitAlgorithmResult] = useState(false);
    const [{agent, user}] = useStateValue();

    const override: CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
    };

    async function addCRMHistory(message) {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();

        const data = {
            crmUserId: agent.agentId,
            userId: props.userId,
            comment: message
        }

        Parse.Cloud.run("addUserComment", data);
    }
    const getUserSubscriptionStripe = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        return await Parse.Cloud.run("getUserSubscriptionStripe",{ wollerId: props.userId });
    }

    async function doUpdateUserSubscriptionInWolly(action) {
        if (!["reactivate", "cancel"].includes(action)) {
            setShowError(true);
            setErrorMessage("Acción no permitida");
        }

        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        return await Parse.Cloud.run("updateSubscription",{ wollerId: props.userId, planId: wollyPlanId, action: action });
    }

    async function doCreateUserSubscriptionInWolly() {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        return await Parse.Cloud.run("createNewSubscription",{ wollerId: props.userId, planName: wollyPlanName });
    }

    async function createSubscriptionStripeByCheckoutSession(planName){
        try {
            return Parse.Cloud.run("createSubscriptionStripeByCheckoutSession",{ wollerId: props.userId, planName: planName });
        } catch (error) {
            setShowError(true);
            setErrorMessage(error?.message ?? "No fue posible crear portal Stripe para nueva suscripción");
            console.error(error);
        }
    }

    function _calculateMessageAvailableServices(subscription) {
        const limitationResult = subscription?.storedUserSubscription?.limitationResult ?? null;

        if (typeof limitationResult !== 'object' || limitationResult === null) {
            return "Ninguno. Motivo: " + limitationResult ?? "Desconocido";
        }

        //return `Quedan ${limitationResult.dayServicesAvailable} servicios para hoy de los cuales ${limitationResult.dayServicesLeft} pueden estar sin completar.`
        return `Quedan ${limitationResult.currentServicesAvailable} servicios y puede tener ${limitationResult.currentServicesAvailableUnmodified} abiertos`
    }

    async function _updateComponentStatus(subscription) {
        setCheckActive(subscription.storedUserSubscription.active ?? false);
        setCheckActiveStripe(subscription.subscriptionInfo.active ?? false);
        setWollyPlanName(subscription.storedUserSubscription.currentPlan ?? "Sin suscripción");
        setWollyPlanId(subscription.storedUserSubscription.currentPlanId);
        setPlanNameStripe(subscription.subscriptionInfo.planName ?? "Desconocido");
        setEndDate(subscription.storedUserSubscription.endDatePeriod instanceof Date ? subscription.storedUserSubscription.endDatePeriod : false);
        const endDateStripe = subscription.stripeSubscriptionInfo.length > 0 ? new Date(subscription.stripeSubscriptionInfo[subscription.stripeSubscriptionInfo.length - 1].current_period_end * 1000) : false;
        setEndDateStripe(endDateStripe instanceof Date ? endDateStripe : false);
        setLimitAlgorithmResult(_calculateMessageAvailableServices(subscription));
        const stripePortal = subscription.stripeSubscriptionInfo.length > 0 && subscription.customerSubscriptionPortal ? subscription.customerSubscriptionPortal : "";
        setPortalLinkStripe(stripePortal ?? "");

        if (stripePortal.length < 1 && plans.hasOwnProperty(subscription?.storedUserSubscription?.currentPlan)) {
            setPortalLinkStripe(await createSubscriptionStripeByCheckoutSession(subscription?.storedUserSubscription?.currentPlan));
        }
    }

    async function updateCurrentSubscription(action) {
        setLoading(true);
        doUpdateUserSubscriptionInWolly(action).then(async () => {
            const message = "reactivate" === action ? "Suscripción activada desde módulo de suscripciones" : "Suscripción cancelada desde módulo de suscripciones";
            await addCRMHistory(message);
            const subscription = await getUserSubscriptionStripe();
            await _updateComponentStatus(subscription);
            setLoading(false);
        }).catch((error) => {
            setShowError(true);
            setErrorMessage(error?.message ?? "No fue posible actualizar la suscripción");
            setLoading(false);
        });
    }

    async function createSubscriptionPlan() {
        setLoading(true);
        doCreateUserSubscriptionInWolly().then(async () => {
            const message = "Suscripción en Wolly creada desde módulo de suscripciones";
            await addCRMHistory(message);
            const subscription = await getUserSubscriptionStripe();
            await _updateComponentStatus(subscription);
            setLoading(false);
        }).catch((error) => {
            setShowError(true);
            setErrorMessage(error?.message ?? "No fue posible crear la suscripción");
            setLoading(false);
        });
    }

    function isNotExpired(endDatePeriod) {
        return new Date(endDatePeriod) > new Date();
    }

    const isDateNearExpired = (endDatePeriod) => {
        const currentDate = new Date();
        const fiveDaysFromNow = new Date(currentDate);
        fiveDaysFromNow.setDate(currentDate.getDate() + 5);

        return new Date(endDatePeriod) <= fiveDaysFromNow && isNotExpired(endDatePeriod);
    };

    const handleChangePlanNameSelect = async e => {
        setWollyPlanName(e.target.value);
        setPortalLinkStripe(await createSubscriptionStripeByCheckoutSession(e.target.value))
    };

    const disableUpdateButtons = () => {
        return wollyPlanId === "" || !user.permissions?.canUpdateSubscriptions;
    }

    const disableCreateButtons = () => {
        return wollyPlanName === "Desconocido" || !user.permissions?.canUpdateSubscriptions;
    }

    const showPlanSelector = () => {
        return (wollyPlanId === "" || wollyPlanName === "Desconocido") && user.permissions?.canUpdateSubscriptions;
    }

    const plans = {
        miniKraken: "Mini Kraken",
        mobyDick: "Moby Dick",
        leviatan: "Leviatán",
        kraken: "Kraken",
        krakenPlus: "Kraken +",
    };

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                return await getUserSubscriptionStripe();
            } catch (error) {
                setShowError(true);
                setErrorMessage(error?.message ?? "No fue posible obtener la información de suscripción");
                console.error(error);
            }
        };

        fetchData().then(async subscription => {
            if (isMounted) {
                await _updateComponentStatus(subscription);

                setLoading(false);
            }
        });

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <section style={style.container}>
            <div style={style.titleContainer}>
                <label style={style.title}>Suscripciones</label>
            </div>
            { showError && (
                <Alert
                    isVisible={showError}
                    onClose={() => setShowError(false)}
                    title={"Algo fue mal..."}
                    subtitle={errorMessage}
                    buttonText={"Entendido"}
                    onClick={() => {
                        setShowError(false);
                    }}
                />)
            }
            {loading ? (
                <div className="sweet-loading" style={style.alignCenter}>
                    <ClipLoader
                        color={"#000000"}
                        loading={loading}
                        cssOverride={override}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            ) : (
                <div>
                    <p style={style.sectionTitle}>
                        <span style={style.textBold}>Suscripción interna en Wolly: </span>
                    </p>
                    <ul>
                        <li><span>Estado en Wolly: </span>
                            <span
                                className={checkActive && isNotExpired(endDate) ? "c-pill c-pill--success" : "c-pill c-pill--danger"}>{checkActive && isNotExpired(endDate) ? "Activa" : "Sin suscripción / Caducada"}
                        </span>
                        </li>
                        <li><span>Fecha vencimiento en Wolly: </span>
                            <span
                                className={isDateNearExpired(endDate) ? "c-pill c-pill--warning" : isNotExpired(endDate) ? "c-pill c-pill--success" : "c-pill c-pill--danger"}>
                                {endDate instanceof Date ? endDate.toLocaleDateString("es") : "Sin suscripción"}
                            </span>
                        </li>
                        <li><span>Nombre del plan en Wolly: </span>
                            <span>{wollyPlanName}</span>
                        </li>
                    </ul>

                    <p style={style.sectionTitle}>
                        <span style={style.textBold}>Suscripción en Stripe: </span>
                    </p>
                    <ul>
                        <li><span>Estado en Stripe: </span>
                            <span
                                className={checkActiveStripe ? "c-pill c-pill--success" : "c-pill c-pill--danger"}>{checkActiveStripe ? "Activa" : "Sin suscripción / Caducada"}
                        </span>
                        </li>
                        <li><span>Fecha vencimiento en Stripe: </span>
                            <span
                                className={isDateNearExpired(endDateStripe) ? "c-pill c-pill--warning" : isNotExpired(endDateStripe) ? "c-pill c-pill--success" : "c-pill c-pill--danger"}>
                                {endDateStripe instanceof Date ? endDateStripe.toLocaleDateString("es") : "Sin suscripción"}
                            </span>
                        </li>
                        <li><span>Nombre del plan en Stripe: </span>
                            <span>{planNameStripe}</span>
                        </li>
                    </ul>
                    <p style={style.sectionTitle}>
                        <span style={style.textBold}>Servicios disponibles: </span>
                        <span>{limitAlgorithmResult}</span>
                    </p>

                    <p style={style.sectionTitle}>
                        <span style={style.textBold}>Acciones: </span>
                    </p>

                    {showPlanSelector() && (
                        <div>
                            <select
                                onChange={handleChangePlanNameSelect}
                                style={style.selectPlan}
                            >
                                <option value="">Seleccione un plan</option>
                                {Object.keys(plans).map((key) => (
                                    <option key={key} value={key}>
                                        {plans[key]}
                                    </option>
                                ))}
                            </select>

                            <Button
                                style={style.renewButton}
                                buttonText={"Crear suscripción en Wolly*"}
                                onClick={() => createSubscriptionPlan()}
                                disabled={showPlanSelector() && disableCreateButtons()}
                            ></Button>
                        </div>
                        )}
                    <Button
                        style={style.portalButton}
                        buttonText={"Portal de gestión de suscripción Stripe"}
                        disabled={showPlanSelector() && disableCreateButtons() && portalLinkStripe?.length < 1}
                        onClick={() => window.open(portalLinkStripe, '_blank')}
                    >
                    </Button>
                    <Button
                        style={style.renewButton}
                        buttonText={"Renovar suscripción actual en Wolly*"}
                        onClick={() => updateCurrentSubscription("reactivate")}
                        disabled={disableUpdateButtons()}
                    >
                    </Button>
                    <Button
                        style={style.cancelButton}
                        buttonText={"Cancelar suscripción"}
                        onClick={() => updateCurrentSubscription("cancel")}
                        disabled={disableUpdateButtons()}
                    >
                    </Button>
                    <small>* Solo para situaciones de extrema necesidad, pagos por transferencia bancaria o ajenos a
                        Stripe</small>
                </div>
            )}
        </section>
    );
}
